import React, { useMemo } from 'react';
import { AutoCompleteData } from 'modals/select';
import { Box, TextField, Autocomplete } from '@mui/material';
import { Controller, useFormState, useForm } from "react-hook-form";
import './common.scss';

type AutocompleteBoxProps = {
    title?: string;
    addClass?: string;
    required?: boolean;
    data: AutoCompleteData[];
    control?: any;
    name?: string;
    type?: string;
    error?: any;
    isDisabled?: boolean
    returnOnlyId?:boolean
}

const AutocompleteBoxCustom: React.FC<AutocompleteBoxProps> = ({ title, data, required, error, addClass, type, control, name, isDisabled,returnOnlyId=false }) => {

    return (


        <Box className={`formGroup ${addClass}`}>

            <Controller
                name={name}
                control={control}
                rules={{ required: true }}

                render={({ field: { onChange, ...field } }) => {

                    return <Autocomplete
                        disabled={isDisabled}
                        disablePortal
                        size='small'
                        name={name}
                        value={field?.value}
                        id="autoComplete"
                        options={data}
                        clearOnBlur={false}
                        autoSelect={true}
                        sx={{ width: '100%' }}
                        onChange={(event,value) => {
                            if(returnOnlyId)
                            onChange(value.id)
                        else
                            onChange(event.target.textContent);
                        }
                        }
                        isOptionEqualToValue={(option, value) => {
                            return option.id === value
                        }}
                        getOptionLabel={(option: string) => {
                            return option?.name || option?.label || data.find((me: any) => me?.id === option)?.name || data.find((me: any) => me?.id === option)?.label || option || ''
                        }}
                        renderInput={(params) => <TextField {...params} size="small" label={title} />}
                        {...field}
                    />
                }} />


            {error && <div className='error'>{error}</div>}

        </Box>
    )
}
export default AutocompleteBoxCustom;