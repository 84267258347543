import { CommonListModel } from "modals/source-model";

export const sourceList:CommonListModel[] = [
    { name: "Hospital", id: "Hospital" },
    { name: "MCH", id: "MCH" },
    { name: "StoreProgram", id: "StoreProgram" },
    { name: "Kiosk", id: "Kiosk" },
    { name: "CallCenter", id: "CallCenter" },
    { name: "Referral", id: "Referral" },
    { name: "DauusTeam", id: "DauusTeam" },
    { name: "Facebook", id: "Facebook" },
    { name: "Website", id: "Website" },
    { name: "Whatsapp", id: "Whatsapp" },
    { name: "Other", id: "Other" },
  ];

  export const ageList:CommonListModel[]= [
    { name: "14-20", id: "14-20" },
    { name: "21-25", id: "21-25" },
    { name: "26-34", id: "26-34" },
    { name: "35 and above", id: "35 and above" },
  ];

  export const  employmentList :CommonListModel[]= [
    { name: "Stay at home", id: "Stay at home" },
    { name: "Working", id: "Working" },
    { name: "Business Owner", id: "Business Owner" },
    // { name: "teacher", id: "teacher" },
    // { name: "Government", id: "Government" },
    // { name: "Health Care", id: "Health Care" },
    // { name: "Corporate Office", id: "Corporate Office" },
    // { name: "Other", id: "Other" },
  ];

  export const educationList:CommonListModel[] = [
    { name: "No School", id: "No School" },
    { name: "Some School", id: "Some School" },
    // { name: "Primary", id: "Primary" },
    // { name: "Middle", id: "Middle" },
    // { name: "High School", id: "High School" },
    { name: "College", id: "College" },
  ];



  export const getInvoiceAmount=(data)=>{
    let amount = data?.map(item => item.qty * item.price).reduce((prev, next) => prev + next);
    return "$ " + amount

  }



  