import React, { useMemo, useState ,useContext ,useEffect } from "react";
import { Box, CircularProgress, Grid, IconButton, TableCell } from "@mui/material";
import NotFound from "components/common/NotFound";
import Popover from "components/common/Popover";
import AddIcon from "@mui/icons-material/Add";
import DeleteDialogBox from "components/common/DeleteDialogBox";
import TableBox from "components/common/TableBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import LoyalVisitDialog from "components/sales/LoyalVisitDialog";
import CompetitionDialog from "components/sales/CompetitionDialog";
import { FormContext } from "context/FormContext";
import { FETCH_SALES_CUSTOMER_INTERACTION } from "graphql/query/sales";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { CREATE_SALES_INTERACTION, DELETE_SALES_INTERACTION } from "graphql/mutation";
import SalesFormDialog from "components/sales/SalesFormDialog";


const Interaction = (props) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openLoyalVisit, setOpenLoyalVisit] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const { formState: FormState } = useContext(FormContext);
  const [sales , setSales] = useState(false)
  const [formlist , setFormList] = useState(FormState.forms?.filter((item)=> item.isNutrition !== true ) );
  const [update, setUpdate] = useState(false)
  const [formData, setFormData] = useState<any>({formTitle:"Loyal Visit Form", formData:[],formType:null});
  const [modalData , setModalData]= useState()
  const [openSalesForm, setSalesFormOpen] = useState(false);

  


  const popupList = useMemo(() => {
    return Object.keys(formlist).map((formId, index) => ({
      id: index + 1,
      title: formlist[formId].name, // Assuming 'name' is the property to be displayed
      onClick: () => {
        const selectedForm = formlist[formId];
        console.log(selectedForm)
        setSalesFormOpen(true);
        setUpdate(false);
        setFormData({ 
          formData: selectedForm, 
          formTitle: selectedForm.name, 
          formType: selectedForm.id 
        });
      },
    }));
  }, [formlist]);

  

   //Create Sales Interaction ... 
   const [createSalesInteraction] = useMutation(CREATE_SALES_INTERACTION, {
    refetchQueries: [{
      query: FETCH_SALES_CUSTOMER_INTERACTION, variables: {
        customerId: Number(props.custdata.id )
    }}],  
    onCompleted(data) {
      if (data.CreateSalesInterAction) {
        setSalesFormOpen(false)
    setUpdate(false)

        setSales(false)
        if (!update) {
          toast.success("Sales interaction created sucessfully");
        }
        else {
          toast.success("Interaction updated sucessfully");
        }
      }
    },
    onError(e:any) {
      console.log({errorsales:e});
    },
   })

  
  //Delete Interaction function ...
  const [deleteSalesInteraction] = useMutation(DELETE_SALES_INTERACTION, {
    refetchQueries: [{
      query: FETCH_SALES_CUSTOMER_INTERACTION, variables: {
        customerId: Number(props.custdata.id )
    }}],  
    onCompleted(data) {
       toast.success("Interaction deleted successfully")
       setOpenDelete(false);

    },
    onError(e) {
    },
   })
  
  

  
  const deleteSalesInteaction = () => {
    deleteSalesInteraction({ variables: { id: Number(itemToDelete?.id) } })
  }
  const handleDeleteClose = () => {
    setOpenDelete(false);

  };

  const headers = [
    {
      key: "interactionType",
      label: "Interaction Type",
    },
    {
      key: "id",
      label: "ID",
    },
    {
      key: "dateOfVisited",
      label: "Date of Visit",
    },
    {
      key: "username",
      label: "Visited by",
    },
    {
      key: "action",
      label: "Action",
      headerProps: { align: "right" },
      props: { align: "right" },
      element:({row})=> (
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton sx={{ color: "#3f51b5" }} onClick={() => handleViewInteraction(row)} >
              <VisibilityIcon style={{ fontSize: 18 }}/>
            </IconButton>
            <IconButton sx={{ color: "#f50057" }} onClick={()=> {
              setItemToDelete(row)
              setOpenDelete(true)
            }}>
              <DeleteIcon style={{ fontSize: 18 }} />
            </IconButton>
          </Box>
        </TableCell>
      ),
    },
  ];


  const handleViewInteraction = (data) => {
       
    setUpdate(true);
    
    const formInfo = {
      index : data?.form?.id,
      name : data?.form?.name,
      type : data?.form?.Ftype
    }; 
              
        if (formInfo) {
    
        const { index, name, type } = formInfo;
        setSalesFormOpen(true); 
         const form = formlist?.filter((item)=> item.id === formInfo.index ) 
               console.log( form ,"asas");
               
        setFormData({ 
            formData: form[0], 
            formTitle: name,
            formType: type, 
            updateData: data 
        });
    }
};

 

  return (
    <React.Fragment>
      {props.loader?  <Grid className="centerLoader"> <CircularProgress  color="primary"/> </Grid> : props?.salesInteractionData?.length ? (
        <Grid className="person">
          <TableBox headers={headers} data={props?.salesInteractionData?props?.salesInteractionData:[]} />
        </Grid>
      ) : (
        <NotFound text="No data found of interaction" />
      )}

      <DeleteDialogBox
        open={openDelete}
        handleClose={handleDeleteClose}
        title="Delete"
        text={`Are you sure you want to delete ${itemToDelete?.id}?`}
        handleDelete={deleteSalesInteaction}
      />
    {openSalesForm &&  <SalesFormDialog 
        title={formData.formTitle}
        open={openSalesForm}
        form={formData.formData}
        formType={formData.formType}
        sales={true}
        handleClose={() => {
          setOpenLoyalVisit(false)
          setSalesFormOpen(false)
    setUpdate(false)

        }}
        updateData={update && formData.updateData}
        custData={props.custdata}
        createSaleFun={createSalesInteraction}
        edit={update}
      />}
     
      <Popover items={popupList}>
        <IconButton aria-label="add" className="addPopup">
          <AddIcon />
        </IconButton>
      </Popover>
    </React.Fragment>
  );
};
export default Interaction;
