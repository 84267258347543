import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton, TableCell, Typography } from "@mui/material";
import NotFound from "components/common/NotFound";
import Popover from "components/common/Popover";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import TableBox from "components/common/TableBox";
import DeleteDialogBox from "components/common/DeleteDialogBox";
import PersonBaseDialog from "components/crm/PersonBaseDialog";
import MotherDialog from "components/crm/MotherDialog";
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useRecoilState } from "recoil";
import moment from 'moment';
import personState from "store/atoms/person/personAtom";
import { BASEPERSON_DELETE_MUTATION, MOTHER_DELETE_MUTATION } from "graphql/mutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import personIdAtom from "store/atoms/person/personIdAtom";
import { KEYS, listProviderAtom } from 'store/atoms/city';
import { toast } from "react-toastify";
import { constants } from "os";
import userDeleteAtom from "store/atoms/person/userDeleteAtom";
import { Mother_SET_Query, PERSON_SET_QUERY } from "graphql/person";
import MotherState from "store/atoms/person/motherAtom";

const Person = () => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openPersonBase, setOpenPersonBase] = useState(false);
  const [openPersonBaseUpdate, setOpenPersonBaseUpdate] = useState(false)
  const [openMother, setOpenMother] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [personBaseData, setPersonBaseData] = useRecoilState(listProviderAtom(KEYS.PERSON_BASE_TABLE))
  const [person, setPerson] = useRecoilState(personState);
  const [personID, setPersonId] = useRecoilState(personIdAtom)
  const [userDelete, setUserDelete] = useState(false)
  const [typeDelete, setTypeDelete] = useState(false)
  const [userDeleteData, setUserDeleteData] = useRecoilState(userDeleteAtom)
  const [mother , setMother] = useRecoilState(MotherState)
  const [edit, setEdit] = useState(false)
  const [editMother, setEditMother] = useState(false)



  //POPup List Button to Open person base and mother base form ..
  const popupList = useMemo(
    () => [
      {
        title: "Person Base",
        onClick: () => {
          setEdit(false)
          setOpenPersonBase(true)

        },
      },
      {
        title: "Mother",
        onClick: () => {
          setOpenMother(true)
          setEditMother(false)
        },
      },
    ],
    []
  );

  //Delete Person base Function..
  const [deletePerson,{}] = useMutation(BASEPERSON_DELETE_MUTATION, {
    onCompleted(data) {
      toast.success("User is deleted successfully")
      setOpenDelete(false)
      // setUserDelete(true)
      setPerson('')
      setUserDeleteData(true)
    },
    onError(e) {
      setOpenDelete(false)
      toast.error("Cannot delete some instances of model 'Person' because they are referenced through protected foreign keys: 'Mother.person'")

    },
  })

  //Delete Mother base function ..
  const [deleteMotherBasePerson, { data: motherData, loading: motherLoading, error: motherError }] = useMutation(MOTHER_DELETE_MUTATION, {
    refetchQueries: [{
      query: Mother_SET_Query, variables: {
        id: parseInt(person?.id),
      }
    }],
    onCompleted(data) {
      toast.success("Mother is deleted successfully")
      setOpenDelete(false)
      // setUserDelete(true)
    },
    onError(e) {
      toast.error(e.message)
      setOpenDelete(false)
    },
  })





  const handleDelete = () => {
    if (typeDelete === true) {
      deletePerson({ variables: { id: parseInt(person?.id) } })
    }
    else {
      deleteMotherBasePerson({ variables: { id: parseInt(person?.mother?.id) } })
    }
  }


  const headers = [
    {
      key: "__typename",
      label: "Type",
    },
    {
      key: "id",
      label: "ID",
    },
    {
      key: "createdDate",
      label: "Date Created",
    },
    {
      key: "action",
      label: "Action",
      headerProps: { align: "right" },
      props: { align: "right" },
      element: ({ row }) => (
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton sx={{ color: "#3f51b5" }}
              onClick={() => {
                setOpenMother(true)
                setEditMother(true)
              }}>
              <VisibilityIcon style={{ fontSize: 18 }} />
            </IconButton>
            <IconButton
              sx={{ color: "#f50057" }}
              onClick={() => {
                setItemToDelete(row)
                setOpenDelete(true);
                setTypeDelete(false)
              }}
            >
              <DeleteIcon style={{ fontSize: 18 }}


              />
            </IconButton>
          </Box>
        </TableCell>
      ),
    },
  ];

  return (
    <React.Fragment>
      {person ? (
        <Grid className="person">
          <Typography variant="h6" className="person__title">
            Basic Information
          </Typography>
          <Box className="person__info">
            <ul>
              <li>
                <b>Name:</b> {person?.name}
              </li>
              <li>
                <b>Phone:</b> {person?.phoneNumber}
              </li>
              <li>
                <b>City:</b> {person?.city?.name}
              </li>
              <li>
                <IconButton aria-label="view" color="primary">
                  <VisibilityIcon onClick={() => {
                    setEdit(true)
                    setOpenPersonBase(true)
                  }

                  } />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={() => {
                    setOpenDelete(true)
                    setItemToDelete(person)
                  }}
                >
                  <DeleteIcon onClick={() => setTypeDelete(true)} />
                </IconButton>
              </li>
            </ul>
          </Box>
          { mother && mother.hasOwnProperty('id')  && <TableBox headers={headers} data={[mother]} />}
        </Grid>
      ) : (
        <NotFound text="No data found of person" />
      )}

      <DeleteDialogBox
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        title="Delete"
        handleDelete={handleDelete}
        text={`Are you sure you want to delete ${itemToDelete?.id}?`}
      />

      <PersonBaseDialog
        open={openPersonBase}
        handleClose={() => setOpenPersonBase(false)}
        title="Person Base"
        persondata={person}
        type={edit}
      />

      <MotherDialog
        open={openMother}
        handleClose={() => setOpenMother(false)}
        title="Mother"
        persondata={mother}
        type={editMother}

      />

      <Popover items={popupList} >
        <IconButton aria-label="add" className="addPopup">
          <AddIcon />
        </IconButton>
      </Popover>
    </React.Fragment>
  );
};
export default Person;
