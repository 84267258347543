import { gql } from "@apollo/client";

export const GET_ITEMS_QUERY = gql`
query{
  items {
    id
    name
    itemDetail {
      price
      priceLevel
      currency {
        id
        code
        __typename
      }
      averagecost
      __typename
    }
    __typename
  }
}
`

export const CITY_QUERY = gql`
  query {
    cities {
      id
      name
      shortName
      isActive
      area {
        city {
          id
          name
          isActive
        }
        id
        name
        isActive
        nhood {
          area {
            id
            name
            isActive
          }
          id
          name
          isActive
        }
      }
      Employee {
        id,
        shortName
        isActive
        person{
          id
          name
        }
        employeeType {
          id
          name

        }
      }
      Hospital {
        id
        name
        isActive
      }
    }
  }
`

export const ME_QUERY = gql`
   query {
    me {
      id
      username
      firstName
      lastName
      city {
        id
        name
        shortName
        isActive
      }
      isStaff
      isActive
      role {
        name
      }

      additionalCities {
        id
        name
        shortName
        isActive
      }
      apps{
        id
        name
      }
    }
  }
`
export const FORMS_SET_QUERY = gql`
  query {
    forms {
      id
      Ftype
      name
      description
      isActive
      isNutrition
      sections {
        id
        displayTitle  
        question {
          id
          displayTitle
          qType
          answerOption {
            name
            displayTitle
            value
          }
        }
      }
    }
  }
`

export const FETCH_PENDING_FINANCE_TRANSACTION=gql `
query FETCH_PENDING_FINANCE_TRANSACTION($cityId: Int!,$orderType: String, $first: Int, $skip: Int) { 
  cityToBeApproved_Orders(cityId: $cityId, first: $first, skip: $skip,orderType:$orderType,) { 
    salesTransactions{
      id
      date
      source 
      customer{
        id
        name
        __typename 
      }
      salesUser{
        id
        username
        firstName
        __typename 
      }
      location{
        id 
         name 
      __typename 
      }
      department{
        id
        name
        __typename 
      }
      currency{
        id
        code
        __typename 
      }
      city {
        id
        , name
        __typename 
      }
      memo
      status
      isFinalized
      rep{
        id
        shortName
      }
      saleorderdetail{
        item{
          id
          name
          __typename 
        }
        id
        qty
        price
        type
        __typename 
      }
      __typename 
    }
    paymentTransactions{
      source 
      id
      date
      customer{
        id
        entityId
        name
        __typename 
      }
      salesUser{
        id
        username
        firstName
        __typename 
      }
      financeUser{
        id
        username
        firstName
        __typename 
      }
      bankAccount {
        id
        name
        __typename 
      }
      location{
        id
        name
        __typename 
      }
      department{
        id
        name
        __typename 
      }
      city {
        id
        , name
      }
      currency{
        id
        code
        __typename 
      }
      memo
      tix
      amount
      status
      isFinalized
      __typename 
    }
    __typename 
  }
}

`


export const FINANCE_DEPARTMENTS =gql`
query{
  departments{
    id
    name
    city {
      id
      name
    }
  }
}
`


export const FINANCE_lOCATIONS =gql`
query{
  locations{
    id
    name
    
  }
}
`
export const FETCH_FINANCE_ACCOUNTS =gql`
query($id:Int!){
  bankAccountCity(cityId:$id){
    id
    name
}
}
`

export const PERSON_QUERY = gql`
  query ($search: String!) {
    persons(search: $search) {
      id
      name
      phoneNumber
      city {
        shortName
      }
      }
    }
`

export const CITY_LIST_CUSTOMER = gql`
query($id: Int!) {
        citystore(id: $id) {
        id
        entityId
        name
        rep{
          shortName
        }
      }
    }

`




