import React, { useState } from "react";
import { MenuItem, Popover as MuiPopover, MenuList } from "@mui/material";

type PopoverProps = {
  items: { title: string; onClick: () => any }[];
  children: any;
  openEl?: any;
  closeEl?: any;
};
const Popover: React.FC<PopoverProps> = ({
  items,
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopup = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };
  
  
  const maxHeight = 350; 

  return (
    <>
      {React.cloneElement(children, { onClick: handleOpenPopup })}
      <MuiPopover
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={!!anchorEl}
        onClose={handleClosePopup}
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            maxHeight: `${maxHeight}px`,
            overflowY: "auto",
            width:'220px'
          },
        }}
      >
        <MenuList autoFocusItem={!!anchorEl}>
          {items.map((item) => (
            <MenuItem
              key={item.title}
              onClick={item.onClick}
              sx={{ fontSize: 14, height: 42, minWidth: 120 }}
            >
              {item.title}
            </MenuItem>
          ))}
        </MenuList>
      </MuiPopover>
    </>
  );
};
export default Popover;
