import React, { useReducer, createContext } from 'react'
import _concat from 'lodash/concat'

export const CitiesActions = {
  SET_CITIES: 'SET_CITIES',
  SET_AREAS: 'SET_AREAS',
  SET_NHOODS: 'SET_NHOODS',
  SET_CITIES_DROPDOWN_DATA:'SET_CITIES_AREAS_NHOOD_DROPDOWN_DATA'

}

//initial state
export const initialState = {

  cities: [],
  areas: [],
  nhoods: [],

}

const reducer = (state, action) => {
  switch (action.type) {
    case CitiesActions.SET_CITIES:
      return {
        ...state,
        cities: action.payload,
      }


    case CitiesActions.SET_CITIES_DROPDOWN_DATA:
      let areas = []
      let nhoods = []

      let cities = action.payload.filter((c) => c.isActive === true)

      cities.forEach((city) => {
        areas = _concat(areas, city.area)
      })

      areas = areas.filter((a) => a.isActive === true)

      areas.forEach((area) => {
        nhoods = _concat(nhoods, area.nhood)
      })

      nhoods = nhoods.filter((n) => n.isActive === true)
      // label-value format to make it easier to work with autocomplete
      return {
        ...state,
          cities: cities.map((city) => ({
            id: city.id,
            value: city.name,
            label: city.name,
            shortName: city.shortName
          })),
          areas: areas.map((area) => ({
            id: area.id,
            value: area.name,
            label: area.name,
            city: area.city.name,
            cityID:area.city.id,
          })),
          nhoods: nhoods.map((nhood) => ({
            id: nhood.id,
            value: nhood.name,
            label: nhood.name,
            area: nhood.area.name,
            areaID: nhood.area.id
          })),
      }
    default:
  }
}

export const CitiesContextProvider = (props) => {
  const [CitiesState, CitiesDispatch] = useReducer(reducer, initialState)

  return (
    <CitiesContext.Provider value={[CitiesState, CitiesDispatch]}>
      {props.children}
    </CitiesContext.Provider>
  )
}

export const CitiesContext = createContext(initialState)
export default CitiesContextProvider
