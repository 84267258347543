import React from "react";
import {
  useLocation,
  useRoutes,
} from "react-router-dom";
import Login from "../pages/login";
import Register from "pages/register";
import Crm from "pages/crm";
import Header from "components/Header";
import Sales from "pages/sales";
import Finance from "pages/finance";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import { AuthContext } from "context/AuthContext";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  TableCell,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";



export const paths = [
  {
    path: "/",
    text: "all",
    element: <PublicRoutes><Login /></PublicRoutes>
  },
  {
    path: "/register",
    text: "all",
    element: <PublicRoutes><Register /></PublicRoutes>,
  },
  {
    path: "/crm",
    text: "crm",
    element: <ProtectedRoutes allowedRoutes={"crm"} > <Crm /></ProtectedRoutes>
  },
  {
    path: "/sales",
    text: "Sales",
    element: <ProtectedRoutes allowedRoutes={"Sales"} > <Sales /></ProtectedRoutes>
  },
  {
    path: "/sales/history",
    text: "Sales",
    element: <ProtectedRoutes allowedRoutes={"Sales"} > <Finance.History /></ProtectedRoutes>
  },
  {
    path: "/finance",
    text: "Finance",
    element: <ProtectedRoutes allowedRoutes={"Finance"} > <Finance.Pending /></ProtectedRoutes>
  },
  {
    path: "/finance/history",
    text: "Finance",
    element: <ProtectedRoutes allowedRoutes={"Finance"} > <Finance.History /></ProtectedRoutes>
  },

];



const Routes = () => {
  const useAuthContext: any = React.useContext(AuthContext);
  const location = useLocation();

  const routes = useRoutes(paths);
 
  

   
  const onboardingPages = ["/", "/register"];
  const hideComponents = onboardingPages.includes(location.pathname)
    ? true
    : false;


  return (
    <React.Fragment>
      {!hideComponents && <Header />}
      {routes}
    </React.Fragment>
  );
};
export default Routes;
