import React, { useMemo } from "react";
import { RadioArrayData } from "modals/select";
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Controller, useFormState, useForm } from "react-hook-form";
import './common.scss';

type RadioBoxProps = {
  title: string;
  data: RadioArrayData[];
  vertical?: boolean;
  groupClass?: string;
  type?: string;
  control?: any;
  name?: string;
  error?:string;
}

const RadioBox: React.FC<RadioBoxProps> = ({ title, data, vertical, error ,groupClass, type, control, name }) => {
  return (
    <Box className={`formGroup ${groupClass}`}>
      <FormControl className={`radioBox ${vertical ? 'vertical' : ''}`}>
        <FormLabel id="gender">{title}</FormLabel>
        {
          control ?
            <Controller
              name={name}
              control={control}
              rules={{ required: true }}
              render={({ field }) => <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                {...field} 
              >
                {data.map((item) => (
                  <FormControlLabel
                    value={item.value}
                    control={<Radio size="small" />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>}

              /> :
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                {data.map((item) => (
                  <FormControlLabel
                    value={item.value}
                    control={<Radio size="small" />}
                    label={item.label}
                  />
                ))}
              </RadioGroup> }

      </FormControl>
      {error && <div className='error'>{error}</div>}

    </Box>
  );
};
export default RadioBox;
