import React, { useMemo } from 'react';
import { Box, TextField } from '@mui/material';
import './common.scss';
import { Controller, useFormState, useForm } from "react-hook-form"

type InputBoxProps = {
    title?: string;
    required?: boolean;
    groupClass?: string;
    multiline?: boolean;
    rows?: number;
    type?: string;
    control?: any;
    name?: string;
    error?: string;
    value?: any
    isDisabled?: boolean,
    usedFor?:string
}



const InputBox: React.FC<InputBoxProps> = ({ title, required, multiline, rows, groupClass, type, control, value, name, error, isDisabled,usedFor },) => {
    // const state = useFormState({ control, name });
    // const isErrorExist = useMemo(() => Boolean(state.errors[name]), [state]);
    // const errorMessage: any = useMemo(() => state.errors[name]?.message || undefined, [state]);
    return (
        <Box className={`formGroup ${groupClass}`}>
            {
                control ?
                    <Controller

                        name={name}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <TextField  size="small" type={type} id="outlined-basic" 
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: type === 'number' && usedFor === "amount" ? 9999999 : undefined,
                                step: type === 'number' && usedFor === "amount" ? 'any' : undefined,
                            },
                        }}
                            multiline={multiline}
                            disabled={isDisabled}
                            value={value}
                            
                            rows={rows} sx={{ width: '100%' }} label={title} variant="outlined"
                            {...field} />}
                            
                    // defaultValue={name}
                    />
                    :
                    <TextField size="small" type={type} id="outlined-basic" multiline={multiline}
                        value={value}

                        disabled={isDisabled}
                        rows={rows} required={required} sx={{ width: '100%' }} label={title} variant="outlined" />

            }
            {/* {isErrorExist && <div className='error'>{error}</div>} */}
            {error && <div className='error'>{error}</div>}

        </Box>
    )
}
export default InputBox;