import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import Logo from 'assets/images/logo.png';
import InputBox from 'components/common/InputBox';
import ButtonBox from 'components/common/ButtonBox';
import './login.scss'
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import "../../utils/rules"
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { LOGIN_MUTATION } from 'graphql/mutation';
import { GET_ITEMS_QUERY, ME_QUERY } from 'graphql/query';
import { toast } from 'react-toastify';
import PasswordBox from 'components/common/PasswordBox ';
import { AuthContext } from 'context/AuthContext';
import { replace } from 'lodash';


interface IFormInputs {
    username: string
    password: string
}
const schema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
})


const Login = () => {

    const useAuthContext = React.useContext(AuthContext)

    const { token, userDetails,allowApps } = useAuthContext

  

    const loginRef = useRef()



    const navigate = useNavigate();

    const { handleSubmit, control, reset, formState: { errors } } = useForm<IFormInputs>({
        defaultValues: {
            username: '',
            password: '',
        },
        resolver: yupResolver(schema)
    });

    const onSubmit = (data: IFormInputs) => {
        tokenAuth({ variables: { ...data } });
    }

    const [tokenAuth, { data, loading, error }] = useMutation(LOGIN_MUTATION, {
        fetchPolicy: 'network-only',
        onCompleted(data) {
            toast.success("Logged In SuccessFully")
            const token = data.tokenAuth.token
            useAuthContext.setToken(token)
            useAuthContext.doLogin()  
        },
        onError(error) {
            toast.error(error.message)

        },
    }
    );

    useEffect(() => {
        setTimeout(
            loginRef?.current?.click()
            , 2000
        )
    }, [])

   
    



    return (
        <>
            {token ?
                "" :
                <Grid className='login' ref={loginRef}  >
                    <Box className='login__cover' >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <img src={Logo} alt='logo' />
                            <Typography variant='h3'>Login</Typography>
                            <InputBox title="Username" control={control} name="username" error={
                                errors?.username?.message}
                            />
                            {/* <div className='error'>{errors.username.message}</div> */}
                            <PasswordBox title="Password" control={control} name='password' />
                            <ButtonBox type="contained" label='Login' btnClass='btn-primary' loading={loading} />
                            <Typography component='small'>Don't Have An Account? <b onClick={() => navigate("/register")}>Sign Up</b></Typography>
                        </form>
                    </Box>
                </Grid>}
        </>
    )
}
export default Login;
