import { ApolloClient, InMemoryCache, createHttpLink ,makeVar } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';


const url= process.env.REACT_APP_API_END_POINT_GRAPHQL;

const httpLink = createHttpLink({  uri: url });
//"https://api-test.dauus.com/oYgP57PTgUijH4T/graphql/"
//"https://api-prod.dauus.com/oYgP57PTgUijH4T/graphql/"})
export const isLoggedInVar = makeVar(!!localStorage.getItem('authToken'));
 
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('t');
  if(token) {
  // return the headers to the context so httpLink can read them
  return {
      headers: {
          ...headers,
          Authorization: token ? `JWT ${token}` : ""
      }
  }
}
});
export const aplloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
//   fetchOptions: {
//     mode: 'no-cors',
// }
});

export default aplloClient;

