import React, { useMemo } from "react";
import {  TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Controller, useFormState, useForm } from "react-hook-form";
import "./common.scss";

type DatepickerBoxProps = {
  title: string;
  required?: boolean;
  groupClass?: string;
  onChange: (value: any) => void;
  value?: string;
  type?: string;
  control?: any;
  name?: string;
  error?: any;
};

const DatepickerBox: React.FC<DatepickerBoxProps> = ({
  title,
  required,
  groupClass,
  onChange,
  value,
  type,
  control,
  name,
  error
}) => {

  const state = useFormState({ control, name });



  const isErrorExist = useMemo(() => Boolean(state.errors[name]), [state]);
  
  const errorMessage: any = useMemo(() => state.errors[name]?.message || undefined, [state]);
  
  return (
    <Box className={`formGroup ${groupClass}`}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>

        {
          control ?
            <Controller
              name={name}
              control={control}
              rules={{ required: false }}
              render={({ field }) => <MobileDatePicker
              
              maxDate={new Date()}
                label={title}
                inputFormat="YYYY-MM-DD"
                // value={value}
                onChange={onChange}
                renderInput={(params) => (
                  <TextField size="small" sx={{ width: "100%" }} {...params} />
                )}
                {...field} 
              />}
            /> :
            <MobileDatePicker
              label={title}
              inputFormat="YYYY-MM-DD"
              // value={value}
              onChange={onChange}
              renderInput={(params) => (
                <TextField size="small" sx={{ width: "100%" }} {...params} />
              )}
            />
        }

      </LocalizationProvider>
      {error && <div className='error'>{error}</div>}

    </Box>
  );
};
export default DatepickerBox;
