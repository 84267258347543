import { matchRoutes, useLocation } from "react-router-dom"
import { paths } from "routes"


const useCurrentPath = () => {
  const location = useLocation()
  const [route]= matchRoutes(paths, location) || []

  return (route as any)?.route?.path || location?.pathname
}

export default useCurrentPath;