import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,

} from "@mui/material";
import { TextField, Autocomplete } from '@mui/material'
import InputBox from "components/common/InputBox";
import AutocompleteBox from "components/common/AutocompleteBox";
import SelectBox from "components/common/SelectBox";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from '@mui/icons-material/Add';
import "../common/common.scss";
import { APPROVE_PENDING_FINANCE_SALES_TRANSACRION, CHECK_GROSS_MARGIN, CREATE_SALES_ORDER_MUTAITON2, UPDATE_FINANCE_SALES_ORDER ,SALES_APPROVAL_STORE_ORDER} from "graphql/mutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { FormContext } from "context/FormContext";
import { FETCH_PENDING_FINANCE_TRANSACTION } from "graphql/query";
import { GET_SUBMITTED_ORDER_TRANSACTION } from "graphql/query/sales";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import AutocompleteBoxCustom from "components/common/AutocompleteBoxCustom";




type AddSaleDialogProps = {
  title?: string;
  open: boolean;
  handleClose: () => void;
  customerData: any,
  salesTransaction: any,
  edit: boolean;
  from: string
};



const AddSaleDialog: React.FC<AddSaleDialogProps> = ({
  title,
  open,
  handleClose,
  customerData,
  salesTransaction,
  edit,
  from,
  ...rest
}) => {

  const emptyRow = {
    id: null,
    itemId: null,
    qty: 1,
    price: null,
  };
  const emptyFreeRow = {
    id: null,
    itemId: null,
    qty: null,
  };

  const { rowData } = rest


  const [saleList, setSaleList] = useState([emptyRow]);
  const [freeSaleList, setFreeSaleList] = useState([emptyFreeRow]);
  const [tempItems, setTempItems] = useState([])
  const [itemPrices, setItemPrices] = useState([])
  const [transdate, setTransdate] = useState([])
  const { formState: FormState } = useContext(FormContext);
  const [salesError, setSalesError] = useState("")
  const [promoError, setPromoError] = useState("")
  const [memo, setMemo] = useState('')
  const [quantity, setQuantity] = useState('')
  const [grossMargin, setGrossMargin] = useState(null)
  const [isDisabled, setIsDisabled] = useState(rowData?.status === "approved" || rowData?.id || rowData.isFinalized)
  const [isSubmit, setIsSubmitting] = useState(false)
  const orderItems = FormState?.orderItems
  let currencyId = customerData?.currency?.id




  const { currentCity } = FormState

  const cityId = Number(currentCity.id)
  const { financeLocations, financeDepartments } = FormState



  //Date Configurations...
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  const date = yyyy + "-" + mm + "-" + dd;


  //empty data




  //Validate records functions ...
  const validateSalesRecord = () => {
    let result = true;
    if (saleList.length === 0) {
      return true;
    }
    const valid = saleList[saleList.length - 1];

    if (valid) {
      if (valid.itemId === null) result = false;
      if (valid.qty === null || "") result = false;
      if (valid.price === null) result = false;
    }
    if (!result) {
      setSalesError('Please complete all fields')
    } else {
      setSalesError('');
    }
    return result;
  }


  // Add Row function
  const addRow = () => {
    if (validateSalesRecord())
      setSaleList([...saleList, emptyRow]);
  };


  const getTotalValues = () => {
    let total = saleList
      .map((item) => item.qty * item.price)
      .reduce((prev, next) => prev + next);
    return total;
  };

  const validatePromoRecord = () => {
    let result = true;
    if (freeSaleList.length === 0) {
      return true;
    }

    const valid = freeSaleList[freeSaleList.length - 1];

    if (valid) {
      if (valid.itemId === null) result = false;
      if (valid.qty === null || "") result = false;
    }

    if (!result) {
      setPromoError('Please complete all fields above')
    } else {
      setPromoError('');
    }

    return result;
  }

  const onDeleteClick = (i) => {
    let filterData = saleList.filter((item, index) => index !== i);
    setSaleList([...filterData]);
  };

  const addFreeRow = () => {

    if (validatePromoRecord())
      setFreeSaleList([...freeSaleList, emptyFreeRow]);
  };

  const onFreeDeleteClick = (i) => {
    let filteredData = freeSaleList.filter((me, index) => index !== i);
    setFreeSaleList([...filteredData]);
  };


  useEffect(() => {

    if (orderItems?.length > 0) {
      mappItemsInTable(orderItems)
    }
  }, [orderItems?.length])



  const mappItemsInTable = (items) => {
    console.log('mappItemsInTable', { items });


    let customItems = items?.map((item, index) => {
      let currencyItems = item?.itemDetail?.filter(currency => {
        return currency?.currency?.id === (currencyId || 1)
      })

      return {
        id: Number(item?.id),
        name: item?.name,
        qty: 1,
        price: currencyItems,
        value: item?.name
      }
    })
    setTempItems([...customItems])
  }

  // ongross Margin click api ...
  const [onGrossMarginClick, { loading2, data }] = useLazyQuery(CHECK_GROSS_MARGIN, {
    onCompleted(data) {
      let margin = data?.checkGrossMargin?.grossMargin;
      let fixedvalue = Number(margin) * 100
      setGrossMargin(fixedvalue.toFixed(2))
    },
    onError(e) {
      console.log(e)
    }
  })


  //Create sales Order api function ...
  const [CreateSalesOrder] = useMutation(CREATE_SALES_ORDER_MUTAITON2, {
    refetchQueries: [{

      query: GET_SUBMITTED_ORDER_TRANSACTION, variables: { customerId: rowData?.customerId ?rowData?.customerId :Number(customerData?.id), skip: 0, first: 0 },
    },
    { query: FETCH_PENDING_FINANCE_TRANSACTION, variables: { cityId: cityId, first: 100 } }

    ],
    onCompleted(data) {
      setIsSubmitting(false)


      if (!edit) {

        toast.success("Sales Created Sucesssfully");
      }
      else {
        toast.success("Sales Updated Sucesssfully");

      }
      handleClose()
    },
    onError(e) {
      setIsSubmitting(false)

      toast.error(e?.message || "Error")
      console.error(e);
    },
  })

  const [CreateFinanaceSalesOrder] = useMutation(UPDATE_FINANCE_SALES_ORDER, {
    refetchQueries: [{

      query: GET_SUBMITTED_ORDER_TRANSACTION, variables: { customerId: rowData?.customerId ?rowData?.customerId :Number(customerData?.id), skip: 0, first: 0 },
    },
    { query: FETCH_PENDING_FINANCE_TRANSACTION, variables: { cityId: cityId, first: 100 } }

    ],
    onCompleted(data) {
      setIsSubmitting(false)


      if (!edit) {

        toast.success("Sales Created Sucesssfully");
      }
      else {
        toast.success("Sales Updated Sucesssfully");

      }
      handleClose()
    },
    onError(e) {
      setIsSubmitting(false)

      toast.error(e?.message || "Error")
      console.error(e);
    },
  })

  const [approveSalesOrder] = useMutation(APPROVE_PENDING_FINANCE_SALES_TRANSACRION, {
    refetchQueries: [{ query: FETCH_PENDING_FINANCE_TRANSACTION, variables: { cityId: cityId } }, 'GET_MY_SUBMITTED_ORDERS'],

    onCompleted(data) {
      toast.success(data?.approveRejectSalesOrder?.message);
      handleClose()
      setIsSubmitting(false)
    },
    onError(e) {
      toast.error(e?.message || "Error")
      setIsSubmitting(false)
    },
  }) 

  const [approveStoreSalesOrder] = useMutation(SALES_APPROVAL_STORE_ORDER, {
    refetchQueries: [{ query: FETCH_PENDING_FINANCE_TRANSACTION, variables: { cityId: cityId } }, 'GET_MY_SUBMITTED_ORDERS'],

    onCompleted(data) {
      toast.success(data?.salesApprovalStoreOrder?.message);
      handleClose()
      setIsSubmitting(false)
    },
    onError(e) {
      toast.error(e?.message || "Error")
      setIsSubmitting(false)
    },
  })


  const handleSubmitSale = () => {

    if (validateSalesRecord()) {

      setIsSubmitting(true)

      CreateSalesOrder({
        variables: {
          source : rowData?.source,
          input: {
            id: rowData ? parseInt(rowData?.id) : null,
            customerId: rowData?.customerId ?rowData?.customerId :Number(customerData?.id),
            transDate: date,
            memo: memo,
            cityId: cityId,
            saleItems: saleList,
            promoItems: freeSaleList.filter(me => me.itemId !== null && me.qty !== null),


          },
        },
      })
    };
  }

  const handleSubmitFinanceSale = (params) => {

    if (validateSalesRecord()) {

      setIsSubmitting(true)

      CreateFinanaceSalesOrder({
        variables: {
          source : rowData?.source,
          input: {
            salesOrderId: rowData ? parseInt(rowData?.id) : null,
            customerId: rowData?.customerId ?rowData?.customerId :Number(customerData?.id),
            transDate: date,
            memo: memo,
            repId: rowData?.repId,
            currencyId: rowData.currencyId,
            cityId: cityId,
            saleItems: saleList,
            promoItems: freeSaleList.filter(me => me.itemId !== null && me.qty !== null),
            ...params

          },
        },
      })
    };
  }

  // Check onCLick goss margin api ...

  const onClickGrossMargin = () => {
    if (validateSalesRecord()) {
      onGrossMarginClick({
        variables: {
          input: {
            id: null,
            customerId: rowData?.customerId ?rowData?.customerId :Number(customerData?.id),
            transDate: date,
            memo: memo,
            cityId: cityId,
            saleItems: saleList,
            promoItems: freeSaleList.filter(me => me.itemId !== null && me.qty !== null)
          },
        },
      })
    }
  }

  const onTableRowChange = (type, i, value) => {
    let defaultPrice = null

    let result = []
    if (type === "itemId") {
      defaultPrice = value.price.find(me => me.priceLevel === 1)?.price

      result = saleList.map((me, index) => {
        if (index === i) {
          return {
            ...me, [type]: value.id,
            'price': Number(defaultPrice) ?? Number(defaultPrice)

          };
        } else {
          return me;
        }
      });

    }
    else {
      result = saleList.map((me, index) => {
        if (index === i) {
          return { ...me, [type]: Number(value) };
        } else {
          return me;
        }
      });
    }

    setSaleList([...result]);
  };

  const onFreeTableRowChange = (type, i, value) => {

    let result = []
    if (type === "itemId") {
      result = freeSaleList.map((me, index) => {
        if (index === i) {
          return { ...me, [type]: value.id };
        } else {
          return me;
        }
      });
    }
    else {
      result = freeSaleList.map((me, index) => {
        if (index === i) {
          return { ...me, [type]: Number(value) };
        } else {
          return me;
        }
      });
    }
    setFreeSaleList([...result]);
  };


  const handleSelect = (value, index) => {
    if (value)
      onTableRowChange('itemId', index, value)
  }


  const handlePromoSelect = (value, index) => {
    if (value)
      onFreeTableRowChange('itemId', index, value)
  }




  useEffect(() => {

    if (rowData?.id) {
      let data = rowData?.salesOrderDetails      
      setMemo(rowData.memo)
      mappViewData(data)
    }
  }, [rowData?.length])


  const mappViewData = (data) => {
    let salesData = [];
    let salesData3 = [];


    data?.length > 0 && data?.forEach((me, index) => {
      if (me?.type === "sale") {
        let itemId = me.item?.id
        let obj = {
          id: Number(me.id),
          itemId: itemId,
          qty: Number(me?.qty),
          price: Number(me?.price)
        }
        salesData.push(obj)
      }
      else {
        let itemId = me?.item?.id
        let obj = {
          id: Number(me?.id),
          itemId: itemId,
          qty: Number(me?.qty),

        }
        salesData3.push(obj)
      }
    })




    setSaleList([...salesData])
    setFreeSaleList([...salesData3])
  }

  const onApproveClick = () => {
    setIsSubmitting(true) 
    if(from=== "sales" && rowData?.source === "store_app_order"){
      approveStoreSalesOrder({
       variables: {
         id: Number(rowData?.id),
         isApproved: true,
         source : rowData?.source 
 
       }
     }) 
   }else{
    approveSalesOrder({
      variables: {
        id: Number(rowData?.id),
        isApproved: true,
         source : rowData?.source 
      },
    }) 
  }
  }

  const onRejectClick = () => {
    setIsSubmitting(true) 
    if(from=== "sales" && rowData?.source === "store_app_order"){
       approveStoreSalesOrder({
        variables: {
          id: Number(rowData?.id),
          isApproved: false,
          source : rowData?.source 
  
        }
      }) 
    }else{
    approveSalesOrder({
      variables: {
        id: Number(rowData?.id),
        isApproved: false,
        source : rowData?.source 

      }
    }) 
  }
  }

  useEffect(() => {
    if (rowData?.id) {

    }

  }, [rowData?.id])

  const schema = yup.object({
    locationId: yup.string().required("This field is required."),
    departmentId: yup.string().required("This field is required.")
  })

  const defaultValues = {
    locationId: rowData.location?.id,
    departmentId: rowData?.department?.id
  }

  const { handleSubmit, control, setValue, reset, watch, formState: { errors } } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)

  });

  const onFormSubmit = () => {
    // let data={...control._formValues,control._formValues}}
    const locationIdString = control._formValues.locationId
    const intData = parseInt(locationIdString, 10);
    delete control._formValues.locationId
    control._formValues.locationId = intData

    if (from === "finance") {
      handleSubmitFinanceSale(control._formValues)
    }
    else
      handleSubmitSale()

  }



  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        className: "addSaleDialog",
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>

        {rowData?.customer && <List component={Stack} direction={'row'} spacing={2}>
          <ListItem>
            Name: {rowData?.customer?.name || rowData.customer}

          </ListItem>
          <ListItem>Curency:  {customerData?.currency?.code}</ListItem>

        </List>
        }


        {from === "finance" &&
          <Grid container spacing={2} style={{ marginBottom: '20px' }}>
            <Grid item xs={6}>
              <AutocompleteBoxCustom title={"Location"} data={financeLocations} name="locationId" control={control} error={errors?.locationId?.message} isDisabled={isDisabled} returnOnlyId={true} />
            </Grid>
            <Grid item xs={6}>

              <AutocompleteBoxCustom title={"Department"} data={financeDepartments} name="departmentId" control={control} error={errors?.departmentId?.message} isDisabled={isDisabled} returnOnlyId={true} />
            </Grid>
          </Grid>
        }

        <Grid sx={{ marginBottom: "20px" }}>
          <TableContainer component={Paper} className="tableResponsive">
            <Table className="recordSales">
              <TableHead className="top">
                <TableRow>
                  <TableCell>Sales</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {saleList.map((item, index) => (
                  <TableRow key={index} className="mobileView">
                    <TableCell className="sales">{index + 1}</TableCell>
                    <TableCell className="item">
                      <Autocomplete
                        options={tempItems}
                        getOptionLabel={(option) => option.name === undefined ? tempItems?.find(me => me.id === item.itemId)?.name : option.name}
                        id="cselect"
                        autoSelect
                        disabled={isDisabled}
                        value={item?.itemId}
                        className="autoComplete"
                        // disabled={props?.data?.isFinalized}
                        size="small"
                        onChange={(event, newValue) => {
                          handleSelect(newValue, index);
                        }}
                        renderInput={(params) => <TextField value={item.id} {...params}
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }} />}
                      />
                    </TableCell>
                    <TableCell className="qty">
                      <TextField InputProps={{ inputProps: { min: 1 } }} size="small" type="number" value={item.qty} name="qty" onChange={(e) => onTableRowChange('qty', index, e.target.value)}
                        disabled={isDisabled}

                      />
                    </TableCell>
                    <TableCell className="price">
                      <Select
                        hiddenLabel={true}
                        labelId="dropdown-price"
                        id="dropdown-price"
                        onChange={(e) => onTableRowChange('price', index, e.target.value)}
                        value={item.price}
                        size="small"
                        disabled={isDisabled}
                      >
                        {tempItems && tempItems?.length > 0 && tempItems?.find(me => me?.id === item?.itemId)?.price?.map(item => (
                          <MenuItem value={Number(item.price)}>{Number(item.price)}</MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell className="total">
                      {item.price * item.qty}
                      {index !== 0 && (
                        <IconButton
                          sx={{ color: "#f50057" }}
                          onClick={() => onDeleteClick(index)}
                        >
                          <DeleteOutlineIcon style={{ fontSize: 18 }} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: "right" }}>
                    Total:
                  </TableCell>
                  <TableCell colSpan={1} sx={{ textAlign: "left" }}>
                    {saleList?.length > 0 && getTotalValues()}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          {grossMargin && <Box sx={{
            textAlign: 'right',
            marginTop: '20px'
          }}>
            <Typography component={'span'} sx={{
              fontSize: '14px',
              paddingRight: '20px',
              fontWeight: 700
            }}>

              Gross margin
            </Typography>
            <Typography component={'span'} sx={{
              backgroundColor: grossMargin > 37 ? 'green' : 'red',
              fontWeight: 700,
              padding: '10px'
            }}>

              {grossMargin}
            </Typography>
          </Box>}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "10px",
            }}
          >
            {!isDisabled && <Button
              className="btn-text"
              onClick={addRow}
            >
              <AddIcon /> Add Sale Item
            </Button>}
          </Box>
          <Box sx={{
            textAlign: 'center'
          }}
            className="error">
            {salesError}
          </Box>
        </Grid>
        <Grid sx={{ marginBottom: "20px" }}>
          <TableContainer component={Paper} className="tableResponsive">
            <Table className="mobileViewFree">
              <TableHead>
                <TableRow>
                  <TableCell>Free</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {freeSaleList?.map((item, index) => (
                  <TableRow key={index} className="mobileViewFreeRow">
                    <TableCell className="free">{index + 1}</TableCell>
                    <TableCell className="item">
                      <Autocomplete
                        options={tempItems}
                        getOptionLabel={(option) => option.name === undefined ? tempItems?.find(me => me.id === item.itemId)?.name : option.name}
                        id="cselect"
                        autoSelect
                        disabled={isDisabled}
                        value={item?.itemId}
                        className="autoComplete"
                        // disabled={props?.data?.isFinalized}
                        size="small"
                        onChange={(event, newValue) => {

                          handlePromoSelect(newValue, index);
                        }}
                        renderInput={(params) => <TextField value={item.id} {...params}
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }} />}
                      />
                    </TableCell>
                    <TableCell className="qty" >
                      <TextField InputProps={{ inputProps: { min: 1 } }} type="number" size="small" value={item.qty}
                        disabled={isDisabled}
                        onChange={(e) => {

                          onFreeTableRowChange('qty', index, e.target.value)

                        }} />
                    </TableCell>
                    <TableCell>
                      {index !== 0 && (
                        <IconButton sx={{ color: "#f50057" }} onClick={() => onFreeDeleteClick(index)}>
                          <DeleteOutlineIcon style={{ fontSize: 18 }} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {!isDisabled && <Button
              className="btn-text"
              onClick={() => addFreeRow()}
            >
              <AddIcon /> Add Free Item
            </Button>}
            <Box sx={{
              textAlign: 'center'
            }}
              className="error">
              {promoError}
            </Box>
          </Box>
        </Grid>
        <TextField
          label="Memo"
          type="text"

          // disabled={props?.data?.isFinalized}
          value={memo}
          size="small"
          style={{

            paddingLeft: 0,
            paddingRight: 0,
            textAlign: 'center',
            minWidth: '300px'
          }}
          onChange={(e) => setMemo(e.target.value)}
          disabled={isDisabled}
        /> 
      </DialogContent>
      <DialogActions>

        {isSubmit ?
          <Box sx={{ width: '100%', textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
            <CircularProgress />
          </Box> :
          <>

            {isDisabled && rowData?.status !== "approved" && (from === 'finance'|| rowData?.source === "store_app_order" ) &&
              <>
                <Button className="btn-text" onClick={onApproveClick}>
                  Approve
                </Button>
                <Button className="btn-text" onClick={onRejectClick}>
                  Reject
                </Button>
              </>}


            {rowData.id && isDisabled && rowData?.status !== "approved" &&
              <Button className="btn-text" onClick={() => setIsDisabled(false)}>
                Edit
              </Button>}
            {!isDisabled && rowData?.status !== "approved" && rowData?.status !== "rejected" && <Button className="btn-text" onClick={() => {

              { handleSubmit(onFormSubmit()) }
            }}>
              {rowData.id ? 'Update' : 'Submit'}
            </Button>}
            {saleList?.length > 0 && <Button className="btn-text" onClick={onClickGrossMargin}>
              Gross Margin Check
            </Button>}

            <Button className="btn-text" onClick={handleClose}>
              Cancel
            </Button>


          </>

        }
      </DialogActions>

    </Dialog>
  );
};
export default AddSaleDialog;
