import React, { useState } from "react";
import format from 'date-fns/format'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { add } from 'date-fns';
import { DateRangePicker } from "react-date-range";
import { Box, Grid, Button, Typography, Popover } from "@mui/material";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./common.scss";

type DateRangeBoxProps = {
  title: string;
  addClass?: string;
  required?: boolean;
  onChangeDate?: (date) => void;
  type: any;
};

const DateRangeBox: React.FC<DateRangeBoxProps> = ({
  title,
  required,
  addClass,
  onChangeDate,
  type
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: add(new Date(), { months: 1 }),
      key: "selection",
    },
  ]);
  const handleSelect = (item) => {
    setDate([item.selection]);
    if (type === 'finance') {
      onChangeDate(item?.selection);
    }
    handleClose()
  };
  return (
    <Box className={`formGroup ${addClass}`}>
      <Grid className="datePicker">
        <Button
          aria-describedby={id}
          variant="text"
          onClick={handleClick}
          style={{
            paddingLeft: 15,
            width: "100%",
            height: "40px",
            color: '#3f51b5',
            border: "1px solid #E8ECEF",
            boxShadow: "none !important",
            backgroundColor: "#fff !important",
            justifyContent: "flex-start",
            textTransform: "capitalize",
            textAlign: 'left',
            lineHeight: '20px'
          }}
        >
          <Typography style={{ color: "#77797f", marginRight: 10, lineHeight: '20px' }}>
            {title}
          </Typography>{" "}
          {format(date[0].startDate, "MMM dd, yyyy")} -{" "}
          {format(date[0].endDate, "MMM dd, yyyy")}{" "}
          <ArrowDropDownIcon style={{ marginLeft: "auto", color: "#E8ECEF" }} />
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{ marginTop: "10px" }}
        >
          <Box sx={{ overflow: "auto" }}>
            <DateRangePicker
              ranges={date}
              editableDateInputs={true}
              onChange={(item) => handleSelect(item)}
              rangeColors={["#3f51b5"]}
            />
          </Box>
        </Popover>
      </Grid>
    </Box>
  );
};
export default DateRangeBox;
