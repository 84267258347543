import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from 'assets/images/logo.png';
import InputBox from 'components/common/InputBox';
import PasswordBox from 'components/common/PasswordBox ';
import ButtonBox from 'components/common/ButtonBox';
import './register.scss'
import AutocompleteBox from 'components/common/AutocompleteBox';
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import { REGISTER_MUTATION } from 'graphql/mutation';
import { CITY_QUERY } from 'graphql/query';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { useMutation, useQuery } from '@apollo/client';
import "../../utils/rules";
import AutocompleteBoxCustom from 'components/common/AutocompleteBoxCustom';
import AutocompleteBoxArea from 'components/common/Autocompleteboxarea';

const genderList = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
]

const schema = yup.object({
    name: yup.string().required(),
    phoneNumber: yup.number()
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required('This field is required.'),
    username: yup.string().required(),
    gender: yup.string().required(),
    password: yup.string().required(),
    city: yup.string().required(),
    area: yup.string().required(),
    nhood: yup.string().required(),
})
interface IFormInputs {
    name: string,
    phoneNumber: string,
    username: string,
    gender: any,
    password: string,
    city: any,
    area: any,
    nhood: any,
}

const cityList = [
    { label: 'Hargeisa', },
    { label: 'Burco', },
    { label: 'Borama' },
]
const areaList = [
    { label: 'Hargeisa' },
    { label: 'Burco' },
    { label: 'Borama' },
]
const nhoodList = [
    { label: 'Hargeisa' },
    { label: 'Burco' },
    { label: 'Borama' },
]



const Register = () => {


    const navigate = useNavigate();
    const [city, setCity] = useState([])
    const [area, setArea] = useState([])
    const [nhood, setNhood] = useState([])

    const { handleSubmit, control, watch, reset, formState: { errors } } = useForm<IFormInputs>({
        defaultValues: {
            name: '',
            phoneNumber: '',
            username: '',
            gender: '',
            password: '',
            city: '',
            nhood: '',
            area: '',
        },
        resolver: yupResolver(schema)
    });
    const cityWatch = watch("city");
    const areaWatch = watch("area")


    const onSubmit = (data: IFormInputs) => {
        let inputData = {
            ...data,
            gender:data.gender==="Male"?"M":"F",
            nhood: parseInt(data.nhood),
            city: parseInt(data.city),
            area: parseInt(data.area),
            phoneNumber: String(data.phoneNumber)
        }
        createUser({ variables: { ...inputData } });
    }

    const [createUser, { data, loading, error }] = useMutation(REGISTER_MUTATION, {
        onCompleted(data) {
            toast.success("User is created successfully")
            navigate('/')
        },
        onError(error) {
            toast.error(error.message)
        }
    });

    useQuery(CITY_QUERY, {
        onCompleted(data) {
            const cities = data.cities
            setCity(cities)
        },
    });

    useEffect(() => {
        if (cityWatch) {
            let data = city?.find(me => me?.id === cityWatch)
            setArea(data?.area)
        }
    }, [cityWatch])

    useEffect(() => {
        if (areaWatch) {
            let data = area?.find(me => me?.id === areaWatch)
            setNhood(data?.nhood)
        }
    }, [areaWatch])




    const watchFields = watch(["city"]);

    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {})
        return () => subscription.unsubscribe();
      }, [watch]);

    const onError = (error, e) => {
        console.log(error, e);

    }

    return (
        <Grid className='register'>
            <Box className='register__cover'>
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <img src={Logo} alt='logo' />
                    <Typography variant='h3'>Sign up</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <InputBox type="text" title="Name" control={control} name="name" error={
                                errors?.name?.message} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputBox type="number" title="Phone Number" control={control} name="phoneNumber" />
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteBoxCustom title={'Gender'} data={genderList} control={control} name="gender" error={
                                errors?.gender?.message} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputBox type="name" title="Username" control={control} name="username"
                                error={
                                    errors?.username?.message} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <PasswordBox title="Password" control={control} name="password" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AutocompleteBoxArea title={'City'} data={city} control={control} name="city" error={
                                errors?.city?.message} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AutocompleteBoxArea title={'Area'} data={area} control={control} name="area" error={
                                errors?.area?.message} />
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteBoxArea title={'Nhood'} data={nhood} control={control} name="nhood" error={
                                errors?.nhood?.message} />
                        </Grid>
                        <Grid item xs={12}>
                            <ButtonBox type="contained" label='Sign Up' btnClass='btn-primary' loading={loading} />
                        </Grid>
                    </Grid>
                    <Typography component='small'>Have Already An Account? <b onClick={() => navigate("/")}>Login</b></Typography>
                </form>
            </Box>
        </Grid>
    )
}
export default Register;