import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import "../common/common.scss";
import InputBox from "components/common/InputBox";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { APPROVE_PENDING_FINANCE_PAYMENT_TRANSACRION, APPROVE_PENDING_FINANCE_SALES_TRANSACRION, Finance_Payment_Pre_Authorize, PAYMENT_PRE_AUTHORIZE_PAYMENT } from "graphql/mutation";
import { FETCH_PENDING_FINANCE_TRANSACTION } from "graphql/query";
import { toast } from "react-toastify";
import { TRANSACTIONS_BY_CUSTOMER } from "graphql/query/sales";
import AutocompleteBoxCustom from "components/common/AutocompleteBoxCustom";
import { FormContext } from "context/FormContext";

type MakePaymentDialogProps = {
  id: any,
  locationId: string,
  dept: string,
  bankAccountId: string,
  amount?: number;
  open: boolean;
  tix?: string;
  memo?: boolean;
  handleClose: () => void;
  data: any,
  city: string
  refetch: () => void;

};



const schema = yup.object({
  locationId: yup.string().required("This field is required."),
  departmentId: yup.string().required("This field is required."),
  bankAccountId: yup.string().required("This field is required."),
  amount: yup.string().required("This field is required."),
  memo: yup.string().required("This field is required."),
  tix: yup.string().required("This Field is required.")
})


interface IFormInputs {
  id: ''
  locationId: '',
  departmentId: '',
  bankAccountId: '',
  amount: '',
  memo: '',
  tix: '',
  city: ''
}

const MakeSalesPayment: React.FC<MakePaymentDialogProps> = ({
  locationId,
  dept,
  bankAccountId,
  amount,
  open,
  tix,
  memo,
  data,
  city,

  handleClose,
  refetch

}) => {
  const { formState: FormState } = useContext(FormContext);
  const [isDisabled, setIsDisabled] = useState(data?.status === "approved"   || !data.isFinalized )
  const { financeLocations, financeDepartments, financeAccounts } = FormState

  const [loader,setLoader]=useState(false)

  const defaultValues = {
    paymentOrderId: data.id,
    customerId: data.customerId,
    currencyId: data.currencyId,
    cityId: data.cityId,
    transDate: new Date()?.toISOString(),
    amount: data?.invoiceAmount,
    memo: data.memo,
    tix: data.tix,
    salesUser: data?.salesUser,
    locationId: data.location.id,
    departmentId: data?.department.id,
    bankAccountId: data?.account?.id || ""
  }


  const { handleSubmit, control, setValue, reset, watch, formState: { errors } } = useForm<IFormInputs>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)

  });

  var today = new Date();


  const [createPayment, { loading, error }] = useMutation(Finance_Payment_Pre_Authorize, {
    refetchQueries: [{ query: FETCH_PENDING_FINANCE_TRANSACTION, variables: { cityId: data?.cityId } },
    { query: TRANSACTIONS_BY_CUSTOMER}, 'GET_MY_SUBMITTED_ORDERS'],

    onCompleted() {
    setLoader(false)

      toast.success('Submitted Payment')
      handleClose()
    },
    onError() {
    setLoader(false)

      toast.error(error)
    }
  }
  );



  const onSubmit = (data: IFormInputs) => {

    setLoader(true)

    delete data.salesUser
    let input = {
      ...data,
      departmentId: Number(data.departmentId),
      locationId: Number(data.locationId)  , 
      bankAccountId:Number(data.bankAccountId) 
    }
    const keyValuePairs = { ...input };





    // Make Payment ...
    createPayment({
      variables: {
        input: keyValuePairs
      },
    });
  }


  const [approvePayment] = useMutation(APPROVE_PENDING_FINANCE_PAYMENT_TRANSACRION, {
    refetchQueries: [ 'GET_MY_SUBMITTED_ORDERS'],

    onCompleted(data) {
      toast.success("Payment Approved Successfully.");
      handleClose()
      setLoader(false)
      refetch()

    },
    onError(e) {
      toast.error(e?.message || "Error")
       setLoader(false)

    },
  })

  const onApproveAndRejectClick = (param) => {
    setLoader(true)
    approvePayment({
      variables: {
        id: Number(data?.id),
        isApproved: param

      },
    })
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        className: "personBaseDialog",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{'Make Payment'}</DialogTitle>
        <DialogContent>
          <Typography component="small" className="note">{'To record a pre-payment for in'}</Typography>
          <Grid className="row">
            <AutocompleteBoxCustom title={"Location"} data={financeLocations} name="locationId" control={control} error={errors?.locationId?.message} isDisabled={isDisabled} returnOnlyId={true} />
          </Grid>
          <Grid className="row">

            <AutocompleteBoxCustom title={"Department"} data={financeDepartments} name="departmentId" control={control} error={errors?.departmentId?.message} isDisabled={isDisabled} returnOnlyId={true} />
          </Grid>
          <Grid className="row">

            <AutocompleteBoxCustom title={"Accounts"} data={financeAccounts} name="bankAccountId" control={control} error={errors?.bankAccountId?.message} isDisabled={isDisabled} returnOnlyId={true} />
          </Grid>
          <Grid className="row">


            <InputBox title="Amount" groupClass="col-6" type="" name="amount" control={control} error={errors?.amount?.message} isDisabled={isDisabled}  usedFor="amount" />
          </Grid>
          <Grid className="row">


            <InputBox title="Memo" groupClass="col-6" type="" name="memo" control={control} error={errors?.memo?.message} isDisabled={isDisabled} />
          </Grid>
          <Grid className="row">


            <InputBox title="Tix" groupClass="col-6 mb-0" name="tix" control={control} error={errors?.tix?.message} isDisabled={isDisabled} />
          </Grid>
        </DialogContent>
        <DialogActions>
    {loader ? (
     <Box sx={{ width: '100%',textAlign:'center' }}>
     <CircularProgress />
   </Box>
    ) : (
      <>
        {isDisabled && data.status!=="approved"  && data.status!=="rejected" &&
          <>
           {data?.account?.id &&
           <>
           <Button className="btn-text" onClick={() => onApproveAndRejectClick(true)}>
              Approve
            </Button>
            <Button className="btn-text" onClick={() => onApproveAndRejectClick(false)}>
              Reject
            </Button>
            </>}
            <Button className="btn-text" onClick={() => setIsDisabled(false)}>
              Edit
            </Button>
          </>}
        
          {data.status!=="approved"&& data.status!=="rejected" && <Button className="btn-text" type="submit">
            Submit
          </Button>}
        
        <Button className="btn-text" onClick={handleClose}>
          Cancel
        </Button>
      </>
    )}
  </DialogActions>      </form>
    </Dialog>
  );
};

const T = (props) => {
  if (!props.open) return null;
  return <MakeSalesPayment {...props} />
}
export default T;
