import React, { useMemo, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import './common.scss';
import { Controller, useFormState, useForm } from "react-hook-form"


type PasswordBoxProps = {
    title: string;
    required?: boolean;
    control?: any;
    name?: string
}
const PasswordBox: React.FC<PasswordBoxProps> = ({ title, required, control, name }) => {
    const state = useFormState({ control, name });
    const isErrorExist = useMemo(() => Boolean(state.errors[name]), [state]);
    const errorMessage: any = useMemo(() => state.errors[name]?.message || undefined, [state]);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };
    return (
        <Box className='formGroup'>
            <FormControl sx={{ m: 0, width: '100%' }} size="small" variant="outlined" required={required}>
                <InputLabel size="small" htmlFor="outlined-adornment-password">{title}</InputLabel>

                {control ? <Controller name={name}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        size="small"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    size='small'
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff style={{ fontSize: '18px' }} /> : <Visibility style={{ fontSize: '18px' }} />}
                                </IconButton>
                            </InputAdornment>
                        }
                        {...field}

                        label="Password"


                    />}
                /> :
                    <OutlinedInput id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        size="small"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    size='small'
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff style={{ fontSize: '18px' }} /> : <Visibility style={{ fontSize: '18px' }} />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                }
            {isErrorExist && <div className='error'>{errorMessage}</div>}

            </FormControl>
        </Box>

    )
}
export default PasswordBox;