import React, { useContext, useMemo } from 'react';
import { AutoCompleteData } from 'modals/select';
import { Box, TextField, Autocomplete } from '@mui/material';
import { Controller, useFormState, useForm } from "react-hook-form";
import './common.scss';
import { FormContext } from 'context/FormContext';

type EmployeeAutoCompleteProps = {

    control?: any;
    name?: string;

    error?: any;
}

const EmployeeAutoComplete: React.FC<EmployeeAutoCompleteProps> = ({ control, name, error }) => {

    const { formState } = useContext(FormContext);

    const { employees } = formState.cityDropdown


    return (


        <Box className={`formGroup`}>

            <Controller
                name={name}
                control={control}
                rules={{ required: true }}

                render={({ field: { onChange, ...field } }) => {

                    return <Autocomplete
                        disablePortal
                        size='small'
                        name={name}
                        value={field?.value}
                        id="autoComplete"
                        options={employees}
                        groupBy={(option) => option.group}
                        clearOnBlur={false}
                        autoSelect={true}
                        sx={{ width: '100%' }}
                        onChange={(event, value) => {
                            onChange(Number(value.id))

                        }
                        }

                        isOptionEqualToValue={(option, value) => {
                            return option.id === value
                        }}
                        getOptionLabel={(option: string) => {
                            return option?.name ||  employees.find((me: any) => me?.id === option)?.name ||  option || ''
                        }}
                        renderInput={(params) => <TextField {...params} size="small" label={'Employee'} />}
                        {...field}
                    />
                }} />


            {error && <div className='error'>{error}</div>}

        </Box>
    )
}
export default EmployeeAutoComplete;