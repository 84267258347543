import React, { useContext, useEffect, useState } from "react";
import InputBox from "components/common/InputBox";
import RadioBox from "components/common/RadioBox";
import DatepickerBox from "components/common/DatepickerBox";
import AutocompleteBox from "components/common/AutocompleteBox";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { format, isLeapYear } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import "../../utils/rules";
import "../common/common.scss";
import AutocompleteBoxCustom from "components/common/AutocompleteBoxCustom";
import { PERSON_CHILD_CREATE_MUTATION } from '../../graphql/mutation';
import { toast } from "react-toastify";
import { useLazyQuery, useMutation } from "@apollo/client";
import motherIdAtom from "store/atoms/person/motherIdAtom";
import { useRecoilState } from "recoil";
import childAtom from "store/atoms/person/childDeleteAtom";
import { AuthContext } from "context/AuthContext";
import { IdContext } from "context/IdContext";
import { Mother_SET_Query, PERSON_SET_QUERY } from "graphql/person";
import personIdAtom from "store/atoms/person/personIdAtom";
import personState from "store/atoms/person/personAtom";
import { FormContext } from "context/FormContext";
import AutocompleteBoxArea from "components/common/Autocompleteboxarea";
import MotherState from "store/atoms/person/motherAtom";
import CustomLoader from "components/common/CustomLoader";


const schema = yup.object({
  name: yup.string().required(),
  hospital: yup.string().required(),
  DOB: yup.string().required(),
  gender: yup.string().required(),
  vaccine: yup.string().required(),
})
interface IFormInputs {
  name: string,
  hospital: string,
  DOB: String,
  gender: String,
  vaccine: String
}


type ChildrenDialogProps = {
  title?: string,
  open: boolean,
  handleClose: () => void,
  persondata: any,
  type: boolean,
};



const ChildrenDialog: React.FC<ChildrenDialogProps> = ({
  title,
  open,
  handleClose,
  persondata,
  type
}) => {

  const [value, setValues] = useState();
  const [motherID, setMotherId] = useRecoilState(motherIdAtom)
  const [person, setPerson] = useRecoilState(personState);
  const [submitLoader, setSubmitLoader] = useState(false)

  const handleChange = (newValue: any) => {
    setValues(newValue);
  };

  const { formState } = useContext(FormContext);
  const { hospitals } = formState.cityDropdown
  
  const context = IdContext
  const PersonIdContext = useContext(context)
  const [mother , setMother] = useRecoilState(MotherState)


  //Add Child Function
  const [createChildBase, { data, loading, error }] = useMutation(PERSON_CHILD_CREATE_MUTATION, {
    onCompleted(data) {
    let id=parseInt(person?.id)

    getMotherDetails({ variables: { id: id } })
      if (type) {
        toast.success("Child is updated successfully")
      }
      else {
        toast.success("Child is created successfully")
      }
      handleClose()

      setSubmitLoader(false)
      PersonIdContext.setPersonContext()
    },
    onError(error) {
      toast.error(error.message)
      handleClose()
      setSubmitLoader(false)
    }
  });

  const [getMotherDetails,{}] = useLazyQuery(Mother_SET_Query, {
    fetchPolicy:'cache-and-network',
 
    onCompleted(data) {
      setMother(data?.mother)
      const motherId = parseInt(data?.mother?.id)
      let result = { ...person, ...data }
      setPerson(result)
      if(data?.mother) 
      setMotherId(motherId)
      const personId = parseInt(person?.id)
      handleClose()

    },
    onError(error) {
      console.log(error)

    },
  })



  useEffect(() => {
    if (persondata && type) {
      setValue('name', type && persondata?.name)
      setValue('hospital', type && persondata?.hospital?.id)
      setValue('DOB', type && persondata?.DOB)
      setValue('gender', type && persondata?.gender)
      setValue('vaccine', type && persondata?.vaccine === "no" ? "no" : "yes")
    }
    else {
      setValue('name', '')
      setValue('hospital', '')
      setValue('DOB', '')
      setValue('gender', '')
      setValue('vaccine', '')
    }

  }, [type])


  const { handleSubmit, control, setValue, reset, formState: { errors } } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  const formatDate = (date) => {
    if (date && date !== undefined) {
      let newDate = new Date(date);
      return format(newDate, 'yyyy-MM-dd')
    }
  }


  const onSubmit = (data: IFormInputs) => {
    setSubmitLoader(true)

    
    let inputData = {
      ...data,
      DOB:formatDate(data?.DOB),
      mother: parseInt(motherID),
      hospital: parseInt(data.hospital),
      id: type ? parseInt(persondata?.id) : null,

    }
    createChildBase({ variables: { ...inputData } });
  };

 


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        className: "personBaseDialog",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>

        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid className="row">

            <InputBox required title={"Child's name"} name="name" control={control} error={errors?.name?.message} />

            <AutocompleteBoxArea title={"Hospital"} data={hospitals} control={control} name="hospital" error={errors?.hospital?.message} />
            
            {/* <InputBox title={"Date of Birth"} name="DOB" control={control} error={errors?.DOB?.message} /> */}
            <DatepickerBox title="Date of Birth" value={value} onChange={handleChange} name="DOB" control={control} />

            <RadioBox
              title="Gender:"
              vertical
              data={[
                {
                  label: "Male",
                  value: "M",
                },
                {
                  label: "Female",
                  value: "F",
                },
              ]}
              name="gender"
              control={control}
              error={errors?.gender?.message}
            />
            <RadioBox
              title="Vaccine:"
              vertical
              data={[
                {
                  label: "Yes",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                },
              ]}
              name="vaccine"
              control={control}
              error={errors?.vaccine?.message}
            />
          </Grid>
        </DialogContent>
        {submitLoader ? <CustomLoader /> :
        <DialogActions>
          <Button className="btn-text" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="btn-text" type="submit">
            {type ? "Update" : "Save"}
          </Button>
        </DialogActions>}
      </form>
    </Dialog>
  );
};

export default ChildrenDialog;
