import React from 'react';
import { Box, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import './common.scss';

type ButtonBoxProps = {
    type: any;
    btnClass: string;
    label: string;
    click?: ()=> void;
    loading?:boolean
}
const ButtonBox:React.FC<ButtonBoxProps> = ({ type, btnClass, label, click,loading=false }) => {
    return (<>
        {type && <Box className='customBtn'>
            <Button variant={type} className={btnClass} onClick={click} type="submit" disabled={loading}>{loading? <CircularProgress  color="primary"/>:label}</Button>
        </Box>}

        
    </>)
}
export default ButtonBox;