import React from "react";
import { Box, Tab, Tabs } from "@mui/material";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};
const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type TabBoxProps = {
  items: { element: React.ReactNode; label: string }[];
};

const TabBox: React.FC<TabBoxProps> = ({ items }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={value} onChange={handleChange} aria-label="tabs">
        {items?.map((item, idx) => (
          <Tab label={item?.label} {...a11yProps(idx)} />
        ))}
      </Tabs>
      {items?.map((item, idx) => (
        <TabPanel key={item?.label} value={value} index={idx}>
          {item?.element}
        </TabPanel>
      ))}
    </Box>
  );
};
export default TabBox;
