import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import { aplloClient } from 'apploclient/ApolloClient';
import CustomToaster from 'components/common/CustomToaster';
import AuthContextProvider from 'context/AuthContext';
import CitiesContextProvider from 'context/CitiesContext';
import { RecoilRoot } from "recoil";
import IdContextProvider from 'context/IdContext';
import FormContextProvider from 'context/FormContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  
  <ApolloProvider client={aplloClient}>
    <CustomToaster />
    <RecoilRoot>
      <FormContextProvider>
      <IdContextProvider>
    <AuthContextProvider>
      <CitiesContextProvider>
      <App />
      </CitiesContextProvider>
        </AuthContextProvider>
        </IdContextProvider>
        </FormContextProvider>
    </RecoilRoot>
  </ApolloProvider>,

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
