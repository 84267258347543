import { gql } from "@apollo/client";

export const STORE_CITY_SEARCH = gql`
query ($id: Int!, $search: String!) {
  stores(id: $id, search: $search) {
    id
    entityId
    name
    phone
    currentAr
    rep {
      id
      shortName
      __typename
    }
    lastSalesDate
    currency {
      id
      code
      __typename
    }
    __typename
  }
}
`

export const FETCH_SALES_CUSTOMER_INTERACTION = gql`

query($customerId: Int!) {
  salesCustomerInterActions(customerId: $customerId) {
  id
  interactionBy{
    id
    username
  }
  dateOfVisited
  form{
    id
    name
  }
  customer{
    id
    name
    entityId
    currency{
      id
      code
    }
  }
  salesanswer{
    id
    question{
      id
      name
    }
    salesanswer
  }

}}`

export const GET_SUBMITTED_ORDER_TRANSACTION = gql`
query($customerId: Int!){
  customerSubmitedOrders(customerId:$customerId , first: 10) {
    salesTransactions {
      id
      date
      memo
      isFinalized
      currency {
        id,code
      }
      customer {
        id
        name
      }
      status
      saleorderdetail{
        id
        item {
          id
          name
        }
        type
        price
        qty 
      }
      
      
    }
  }
}



  `

  export const GET_SALES_ORDER_DETAILS = gql`
  query($id: Int!){
    salesOrderDetail(id:$id){
      item{
        id
        name
      }
      id
      price
      qty
      type
    }
  }
  
  `



export const TRANSACTIONS_BY_CUSTOMER = gql`
query($id: Int!) {
    transactions(id: $id) {
		id
    type
    date
    document
    currency
    rep
    memo
    amount
    details{
      name
      qty
      amount
      currency
    }
  }
}
`



