import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  TableCell,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NotFound from "components/common/NotFound";
import Popover from "components/common/Popover";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableBox from "components/common/TableBox";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialogBox from "components/common/DeleteDialogBox";
import AddSaleDialog from "components/sales/AddSaleDialog";
import MakePaymentDialog from "components/sales/MakePaymentDialog";
import {
  DELETE_SALES_ORDER,
  PAYMENT_PRE_AUTHORIZE_PAYMENT,
} from "../../../graphql/mutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {
  GET_SALES_ORDER_DETAILS,
  GET_SUBMITTED_ORDER_TRANSACTION,
} from "graphql/query/sales";
import { log } from "console";

const CustomerInfo = (props) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openSale, setOpenSale] = useState(false);
  const [makePayment, setMakePayment] = useState(false);
  const [editable, setEditable] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [customerData, setCustomerData] = useState(props.custdata);
  const [salesTransaction, setSalesTransaction] = useState([]);
  const [pendingTransaction, setPendingTransaction] = useState([]);
  const [rowData, setRowData] = useState({});
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);

  const [dataLoader, setDataLoader] = useState(false);

  const { salesTransactiondata, loader } = props;
  const popupList = useMemo(
    () => [
      {
        title: "Record a Sales Transaction",
        onClick: () => {
          setRowData({});
          setEditable(false);
          setOpenSale(true);
        },
      },
      {
        title: "Record a Payment",
        onClick: () => setMakePayment(true),
      },
    ],
    []
  );

  const headers = [
    {
      key: "date",
      label: "Date",
    },
    {
      key: "customer",
      label: "Customer",
    },
    {
      key: "invoiceAmount",
      label: "Invoice Amount",
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "action",
      label: "Action",
      headerProps: { align: "right" },
      props: { align: "right" },
      element: ({ row }) => (
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton sx={{ color: "#3f51b5" }} onClick={() => handleEyeClick(row)}>
              <VisibilityIcon style={{ fontSize: 18 }} />
            </IconButton>
            <IconButton
              sx={{ color: "#f50057" }}
              onClick={() => {
                setOpenDelete(true)
                setItemToDelete(row)
              }}
            >
              <DeleteIcon style={{ fontSize: 18 }} />
            </IconButton>
          </Box>
        </TableCell>
      ),
    },
  ];

  const handleEyeClick = (row) => {
    console.log("eye", row);
    setRowData(row);
    setEditable(true);

    setOpenSale(true);

    // getSalesOrderDetails({ variables: { id: Number(row?.id) } })
  };

  const handleSalesDelete = () => {
    deleteOrder({ variables: { id: Number(itemToDelete?.id) } });
  };

  const [deleteOrder] = useMutation(DELETE_SALES_ORDER, {
    refetchQueries: [
      {
        query: GET_SUBMITTED_ORDER_TRANSACTION,
        variables: {
          customerId: Number(props?.custdata?.id),
          skip: 0,
          first: 0,
        },
      },
    ],
    onCompleted(data) {
      toast.success("Record deleted successfully");
      setOpenDelete(false);
    },
    onError(e) {
      toast.error(e?.message || "Error");
    },
  });

  useEffect(() => {
    if (props?.data?.saleOrderDetails?.length > 0) {
      let data = props?.data?.saleOrderDetails;
      console.log("datac", data);
      mappViewData(data);
    }
  }, [props?.data]);

  const [getSalesPaymentOrderDetails, { loading: loading3, data: data3 }] =
    useLazyQuery(GET_SALES_ORDER_DETAILS, {
      onCompleted: async (data) => {
        return data;
      },
      onError(e) {
        console.log(e);
      },
    });

  const mappViewData = (data) => {
    let salesData = [];
    let salesData3 = [];

    data?.map((me, index) => {
      if (me?.type === "SALE") {
        let itemId = me?.item?.id;
        let obj = {
          id: me?.id,
          itemId: itemId,
          qty: me?.qty,
          price: Number(me?.price),
        };
        salesData.push(obj);
      } else {
        let itemId = me.item.id;

        let obj = {
          id: me?.id,
          itemId: itemId,
          qty: me?.qty,
        };
        salesData3.push(obj);
      }
    });
  };

  // Get Sales Order Details ...
  const [getSalesOrderDetails, { loading2, data: sales }] = useLazyQuery(
    GET_SALES_ORDER_DETAILS,
    {
      onCompleted(data) {
        let obj = { ...rowData, ...data };
        setRowData(obj);
        console.log("sales orde Details", data);
        setOpenSale(true);
        setEditable(true);
      },
      onError(e) {
        console.log(e);
      },
    }
  );

  return (
    <React.Fragment>
      {props?.custdata?.name ? (
        <Grid className="person">
          <Typography variant="h6" className="person__title">
            Basic Information
          </Typography>
          <Box className="person__info">
            <ul>
              <li>
                <b>ID:</b> {props?.custdata?.id ? props?.custdata?.id : "N/A"}
              </li>
              <li>
                <b>Name:</b>{" "}
                {props?.custdata?.name ? props?.custdata?.name : "N/A"}
              </li>
              <li>
                <b>Phone:</b>{" "}
                {props?.custdata?.phone ? props?.custdata?.phone : "N/A"}
              </li>
              <li>
              <b>AR Balance:</b> {props?.custdata?.currentAr ? parseFloat(props?.custdata?.currentAr)?.toFixed(2) : "N/A"}
              </li>
              <li>
                <b>Last Sale Date:</b>{" "}
                {props?.custdata?.lastSalesDate
                  ? props?.custdata?.lastSalesDate
                  : "N/A"}
              </li>
              <li>
                <b>Currency:</b>{" "}
                {props?.custdata?.currency?.code
                  ? props?.custdata?.currency?.code
                  : "N/A"}
              </li>
            </ul>
          </Box>

          {loader ? (
            <Grid className="centerLoader">
              {" "}
              <CircularProgress color="primary" />{" "}
            </Grid>
          ) : salesTransactiondata?.length > 0 ? (
            <>
              {" "}
              <Typography variant="h6" className="person__title">
                Pending Transactions
              </Typography>
              <TableBox headers={headers} data={salesTransactiondata} />
            </>
          ) : (
            <NotFound text="No pending transaction" />
          )}
        </Grid>
      ) : (
        <NotFound text="No data found of customer info" />
      )}

      <DeleteDialogBox
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        title="Delete"
        text={`Are you sure you want to delete ${itemToDelete?.id}?`}
        handleDelete={handleSalesDelete}
      />

      {openSale && (
        <AddSaleDialog
          open={openSale}
          handleClose={() => setOpenSale(false)}
          title="Record a sales Transaction"
          customerData={props?.custdata}
          salesTransaction={salesTransaction}
          rowData={rowData}
          edit={editable}
          from="sales"
        />
      )}
      {makePayment && (
        <MakePaymentDialog
          customerData={props?.custdata}
          open={makePayment}
          handleClose={() => setMakePayment(false)}
          title="Make Payment"
          text={`To recored a pre-payment for ${props?.custdata?.name} in ${props?.custdata?.currency?.code}`}
          memo={true}
        />
      )}

      <Popover items={popupList}>
        <IconButton aria-label="add" className="addPopup">
          <AddIcon />
        </IconButton>
      </Popover>
    </React.Fragment>
  );
};
export default CustomerInfo;
