import { AuthContext } from "context/AuthContext";
import React from "react";
import { Navigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  TableCell,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { log } from "console";

const PublicRoutes=({children})=>{
  const useAuthContext: any = React.useContext(AuthContext);
       const {allowApps} = useAuthContext 

       console.log(allowApps,"ghgh");
       
    if (!useAuthContext?.token) {
        // not logged in so redirect to login page with the return url
        return  children 
    }  
     let navigatePage ;
    if(useAuthContext?.token &&allowApps.length>0){ 
      console.log(allowApps.includes("crm"),"dddf");
      
      if(allowApps.includes("crm")){
        navigatePage ="/crm"
          }else if(allowApps.includes("Sales")){
            navigatePage="/sales"
          }else if(allowApps.includes("Finance")) {
            navigatePage="/finance"
          }
  }

  if(useAuthContext?.token && allowApps.length===0){
    return <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Grid item className="centerLoader">
      <CircularProgress color="primary" />
    </Grid>
  </Box> 
  }

  

    // authorized so return child components
    return <Navigate to={navigatePage||"/crm"}/>;
}

export default PublicRoutes