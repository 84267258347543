import { atom, atomFamily } from "recoil";

export const KEYS = {
  CITY:'cityList',
  CURRENT_CITY:'currentCity',
  ITEMS:'itemList',
  FORMS:'formList',
  ORDER_ITEMS:'orderItems',
  FINANCE_DEPARTMENT:'financeDepartments',
  FINANCE_LOCATION: 'financeLocations',
  CURRENT_USER:'currentUser',
  PERSON_BASE_TABLE :'personbasedata',
  PREFERRED_STORE:'prefferrdstore'



}
// creating the state value
export const listProviderAtom = atomFamily({
  key: "listProvider",
  default: []
});


