import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import "../common/common.scss";
import InputBox from "components/common/InputBox";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { PAYMENT_PRE_AUTHORIZE_PAYMENT } from "graphql/mutation";
import { FETCH_PENDING_FINANCE_TRANSACTION } from "graphql/query";
import { toast } from "react-toastify";
import { TRANSACTIONS_BY_CUSTOMER } from "graphql/query/sales";
import { FormContext } from "context/FormContext";
import CustomLoader from "components/common/CustomLoader";

type MakePaymentDialogProps = {
  text?: string;
  open: boolean;
  title?: string;
  memo?: boolean;
  handleClose: () => void;
  customerData?: any;
  custdata?: any;
  paymentData?: any;
  type: any;
};



const schema = yup.object({
  amount: yup.string("Please enter a valid number").required("This field is required."),
  memoValue: yup.string().required("This Field is required.")
})

const Paymentschema = yup.object({
  amount: yup.string("Please enter a valid number").required("This field is required."),
})
interface IFormInputs {
  amount: any,
  memoValue: any,
}

const MakePaymentDialog: React.FC<MakePaymentDialogProps> = ({
  title,
  open,
  handleClose,
  memo,
  text,
  customerData,
  custdata,
  paymentData,
  type,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false)
  const FormState = useContext(FormContext)

  const { currentCity } = FormState.formState

  const cityId = Number(currentCity.id)

  const { handleSubmit, control, setValue, reset, watch, formState: { errors } } = useForm<IFormInputs>({
    defaultValues: {
      amount: '',
      memoValue: ''
    },
    resolver: yupResolver(memo ? schema : Paymentschema)
  });

  var today = new Date();


  const [createPayment, { loading, error }] = useMutation(PAYMENT_PRE_AUTHORIZE_PAYMENT, {
    refetchQueries: [{ query: FETCH_PENDING_FINANCE_TRANSACTION, variables: { cityId: cityId } },
    { query: TRANSACTIONS_BY_CUSTOMER }, 'GET_MY_SUBMITTED_ORDERS'],
    onCompleted(data) {
      setSubmitLoader(false)
      toast.success('Submitted Payment')
      handleClose()
    },
    onError(e) {
      console.error(e);
      setSubmitLoader(false)


    }
  }
  );

  useEffect(() => {
    if (paymentData && type) {
      setValue('amount', type && paymentData?.amount)
    }
    else if (!type) {
      setValue('amount', '')
    }
  }, [type])
  
  const onSubmit = (data: IFormInputs) => {    
    setSubmitLoader(true)
    if (memo) {

      createPayment({
        variables: {
          input: {
            customerId: Number(customerData?.id),
            transDate: today.toISOString(),
            memo: data?.memoValue,
            cityId: cityId,
            amount: data?.amount,

          },
        },
      });
    }
    else if (!memo) {
      let input = {
        customerId: Number(customerData?.id),
        transDate: today?.toISOString(),
        cityId: cityId,
        amount: data?.amount,
      }


      // Make Payment ...
      createPayment({
        variables: {
          input
        },
      });
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        className: "personBaseDialog",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography component="small" className="note">{text}</Typography>
          <Grid className="row">
            <InputBox title="Amount" groupClass="col-12" type="number"
              name="amount" control={control} error={errors?.amount?.message} usedFor="amount" />
            {memo && <InputBox title="Memo" groupClass="col-12 mb-0" name="memoValue" control={control} error={errors?.memoValue?.message} />}
          </Grid>
        </DialogContent>
        {submitLoader ? <CustomLoader /> :
          <DialogActions>
            <Button className="btn-text" onClick={handleClose}>
              Cancel
            </Button>
            {!type && <Button className="btn-text" type="submit">
              Submit
            </Button>}
          </DialogActions>
        }
      </form>
    </Dialog>
  );
};
export default MakePaymentDialog;
