import { gql } from "@apollo/client";

export const PERSON_SET_QUERY = gql`
query($id:Int!) {
  person(id:$id) {
  id
    name
    gender
    phoneNumber
    city {
      id
      name
    }
    area {
      id
      name
    }
    nhood {
      id
      name
    }
    createdDate
    modifiedDate
    interaction {
      id
      comment
      isPositive
      area {
        id
        name
      }
      nhood {
        id
        name
      }
      dateOfVisited
      interactionBy {
        id
        shortName
        person {
          name
          id
          
        }
     
      }
      form {
        id
        name
        Ftype
      }
      answer {
        question {
          id
          name
          displayTitle
        }
        answer
      }
    }
  }
  }
`

export const Mother_SET_Query = gql`

  query($id:Int!){
  mother(id:$id){
  createdDate
  id
  employment
  source
  ageGroup
  education
  hasFacebook
  nochildren
  hasWhatsapp
  smartphone
  doYouHaveLicense
  ownsHouse
  advice
  healthIssue
  vitamens
  
  householdLevel
  preferredStore{
    id
    entityId
    name
  }
  contactPreference
  child {
    id
    name
    vaccine
    gender
    hospital {
      id
      name
    }
    DOB
    visits
  }
}
}`

export const Employee_SET_QUERY = gql`
query($id:Int!){
  employee(id:$id){
    id
    shortName
    city {
      id
      name
    }
    user {
      id
      username
      isSuperuser
      role {
        id
        name
      }
    }
  }
}
`