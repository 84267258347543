import React, { useEffect, useState } from "react";
import InputBox from "components/common/InputBox";
import RadioBox from "components/common/RadioBox";
import AutocompleteBox from "components/common/AutocompleteBox";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import "../../utils/rules";
import "../common/common.scss";
import AutocompleteBoxCustom from "components/common/AutocompleteBoxCustom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { PERSON_MOTHTER_CREATE_MUTATION } from "graphql/mutation";
import { toast } from "react-toastify";
import personIdAtom from "store/atoms/person/personIdAtom";
import { useRecoilState } from "recoil";
import motherPersonIdatom from "store/atoms/person/motherPersonId";
import { CITY_LIST_CUSTOMER } from '../../graphql/query'
import { Mother_SET_Query, PERSON_SET_QUERY } from "graphql/person";
import personState from "store/atoms/person/personAtom";
import storeState from "store/atoms/person/store";
import { KEYS, listProviderAtom } from "store/atoms/city";
import { ageList, educationList, employmentList, sourceList } from "utils/Constant";
import AutocompleteBoxArea from "components/common/Autocompleteboxarea";
import MotherState from "store/atoms/person/motherAtom";
import motherIdAtom from "store/atoms/person/motherIdAtom";
import CustomLoader from "components/common/CustomLoader";






type MotherDialogProps = {
  title?: string;
  open: boolean;
  handleClose: () => void;
  persondata: any,
  type: boolean,
};


const schema = yup.object({
  source: yup.string().required(),
  ageGroup: yup.string().required(),
  employment: yup.string().required(),
  preferredStore: yup.string().required(),
  education: yup.string().required(),
  nochildren: yup.string().required(),
  smartphone: yup.string().required(),
  doYouHaveLicense: yup.string().required(),
  // owncar: yup.string().required(),
  ownhouse: yup.string().required(),
  facebook: yup.string().required(),
  whatsapp: yup.string().required(),
  contactPreference: yup.string().required(),

})
interface IFormInputs {
  source: string,
  ageGroup: string,
  employment: string,
  preferredStore: string,
  education: string,
  nochildren: string,
  smartphone: string,
  doYouHaveLicense: string,
  // owncar: string,
  ownhouse: string,
  facebook: string,
  whatsapp: string,
  contactPreference: string,
}

const MotherDialog: React.FC<MotherDialogProps> = ({
  title,
  open,
  handleClose,
  persondata,
  type,
}) => {

  const defaultValues = {
    source: type ? persondata?.source : '',
    ageGroup: type ? persondata?.ageGroup : '',
    employment: type ? persondata?.employment : '',
    preferredStore: type ? persondata?.preferredStore?.id : '',
    education: type ? persondata?.education : '',
    nochildren: type ? persondata?.nochildren : '',
    smartphone: type ? persondata?.smartphone === false ? 'false' : 'true' : 'false',
    doYouHaveLicense: type ? persondata?.doYouHaveLicense === false ? 'false' : 'true' : 'false',
    // owncar: type ? persondata?.ownsCar === false ? 'false' : 'true' : 'false',
    ownhouse: type ? persondata?.ownsHouse === false ? 'false' : 'true' : 'true',
    facebook: type ? persondata?.hasFacebook === false ? 'false' : 'true' : 'false',
    whatsapp: type ? persondata?.hasWhatsapp === false ? 'false' : 'true' : 'false',
    contactPreference: type ? persondata?.contactPreference === "\"facebook\"" ? "facebook" : persondata?.mother?.contactPreference === "\"both\"" ? "both" : persondata?.mother?.contactPreference === "\"neither\"" ? "neither" : "whatsapp" : ''

  }
  const { handleSubmit, control, setValue, reset, formState: { errors } } = useForm<IFormInputs>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const [person, setPerson] = useRecoilState(personState);
  const [motherPersonId, setMotherPersonId] = useRecoilState(motherPersonIdatom)
  const [preferredStore] = useRecoilState(listProviderAtom(KEYS.PREFERRED_STORE));
  const [motherID, setMotherId] = useRecoilState(motherIdAtom)
  const [submitLoader, setSubmitLoader] = useState(false)


  const [mother, setMother] = useRecoilState(MotherState)

  const [createMother] = useMutation(PERSON_MOTHTER_CREATE_MUTATION, {
    // refetchQueries: [{
    //   query: Mother_SET_Query, variables: {
    //     id: parseInt(person?.id),
    //   }
    // }],
    onCompleted() {
      setSubmitLoader(false)

      let id = parseInt(person?.id)

      getMotherDetails({ variables: { id: id } })



      if (type) {
        toast.success("Mother is updated successfully")
      }
      else {
        toast.success("Mother is created successfully")
      }
    },
    onError(error) {
      toast.error(error.message)
      setSubmitLoader(false)

    }
  })


  const [getMotherDetails, { }] = useLazyQuery(Mother_SET_Query, {
    fetchPolicy: 'cache-and-network',

    onCompleted(data) {
      setMother(data?.mother)
      const motherId = parseInt(data?.mother?.id)
      let result = { ...person, ...data }
      setPerson(result)
      if (data?.mother)
        setMotherId(motherId)
      const personId = parseInt(person?.id)
      handleClose()

    },
    onError(error) {
      console.log(error)

    },
  })



  const onSubmit = (data: IFormInputs) => {
    setSubmitLoader(true)
    let inputData = {
      ...data,
      person: parseInt(motherPersonId),
      nochildren: parseInt(data.nochildren),
      ownhouse: data?.ownhouse === 'true' ? true : false,
      doYouHaveLicense: data?.doYouHaveLicense === 'true' ? true : false,
      smartphone: data?.smartphone === 'true' ? true : false,
      whatsapp: data.whatsapp === 'true' ? true : false,
      facebook: data.facebook === 'true' ? true : false,
      preferredStore: parseInt(data.preferredStore),
      id: type ? parseInt(persondata?.mother?.id) : null,
    };

    if (type) {
      inputData.id = parseInt(persondata?.id)
    }
    createMother({ variables: { ...inputData } });
  }

  const onError = (errors, e) => console.log('the error is ', errors, e);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        className: "personBaseDialog",
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      {open && <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogContent>
          <Grid className="row">
            <AutocompleteBoxCustom title={"Source"} data={sourceList} control={control} name="source" error={errors?.source?.message} />

            <AutocompleteBoxCustom title={"Age Group"} data={ageList} control={control} name="ageGroup" error={errors?.ageGroup?.message} />

            <AutocompleteBoxCustom title={"Employment"} data={employmentList} control={control} name="employment" error={errors?.employment?.message} />


            <AutocompleteBoxArea
              title={"Preferred Store"}
              data={preferredStore}
              control={control}
              name="preferredStore"
              error={errors?.preferredStore?.message}
            />
            <AutocompleteBoxCustom title={"Education"} data={educationList} control={control} name="education" error={errors?.education?.message} />
            <InputBox type="number" title={"Number of Children"} control={control} name="nochildren" error={errors?.nochildren?.message} />
            <RadioBox
              title="has SmartPhone:"
              groupClass="col-4"
              data={[
                {
                  label: "Yes",
                  value: "true",
                },
                {
                  label: "No",
                  value: "false",
                },
              ]}
              name="smartphone"
              control={control}
              error={errors?.smartphone?.message}
            />
            <RadioBox
              title="Do you have license"  // New Key added
              groupClass="col-8"
              data={[
                {
                  label: "Yes",
                  value: "true",
                },
                {
                  label: "No",
                  value: "false",
                },
              ]}
              name="doYouHaveLicense"
              control={control}
              error={errors?.doYouHaveLicense?.message}
            />
            <RadioBox
              title="House Status:"
              groupClass="col-4"
              data={[
                {
                  label: "Rent",
                  value: "true",
                },
                {
                  label: "Owned",
                  value: "false",
                },
              ]}
              name="ownhouse"
              error={errors?.ownhouse?.message}
              control={control}
            />
            <RadioBox
              title="Has Facebook:"
              groupClass="col-4"
              data={[
                {
                  label: "Yes",
                  value: "true",
                },
                {
                  label: "No",
                  value: "false",
                },
              ]}
              name="facebook"
              control={control}
              error={errors?.facebook?.message}
            />
            <RadioBox
              title="Has Whatsapp:"
              groupClass="col-4"
              data={[
                {
                  label: "Yes",
                  value: "true",
                },
                {
                  label: "No",
                  value: "false",
                },
              ]}
              name="whatsapp"
              control={control}
              error={errors?.whatsapp?.message}
            />
            <RadioBox
              title="Contact Preference:"
              groupClass="col-12"
              data={[
                {
                  label: "Facebook",
                  value: "facebook",
                },
                {
                  label: "Whatsapp",
                  value: "whatsapp",
                },
                {
                  label: "Both",
                  value: "both",
                },
                {
                  label: "Neither",
                  value: "neither",
                },
              ]}
              name="contactPreference"
              control={control}
              error={errors?.contactPreference?.message}
            />
          </Grid>
        </DialogContent>
        {submitLoader ? <CustomLoader /> :
          <DialogActions>
            <Button className="btn-text" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="btn-text" type="submit">
              {type === true ? 'Update' : 'Save'}
            </Button>
          </DialogActions>}
      </form>}
    </Dialog>
  );
};

const T = (props) => {
  if (!props.open) return null;
  return <MotherDialog {...props} />
}
export default T;
