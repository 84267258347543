import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import "./common.scss";
import MotherDialog from "components/crm/MotherDialog";

type TableBoxProps = {
  headers: {
    label: string;
    key: string;
    element?: any;
    props?: any;
    headerProps?: any;
  }[];
  data: any[];
};

const TableBox: React.FC<TableBoxProps> = ({ headers, data }) => {
  const theme = useTheme(); // Access the current theme
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm")); //  Check if screen size is small


  return (
    <>
      {isSmScreen ? ( // Render different table layout for small screens
        <TableContainer className="tableResponsive" component={Paper}>
          {data.map((row, index) => (
            <React.Fragment key={index}>
              <Table size="small" aria-label="simple table">
                <TableBody>
                  {headers.map((head, headIndex) => {
                    const El = head?.element;

                    const key = `${index}-${head?.key}`;
                    if (El) {
                      return (
                        <React.Fragment key={key}>
                          <TableRow>
                            <TableCell
                              style={{
                                backgroundColor: "#E2E2EA",
                                width: "180px",
                                border: "1px solid #E2E2EA",
                              }}
                            >
                              {head.label}
                            </TableCell>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "100%",
                                overflow: "hidden",
                                backgroundColor:"#f7f7f7"
                              }}
                            >
                              <El
                                row={row}
                                style={{
                                  justifyContent: "flex-start",
                                  width: "100%",
                                  overflow: "hidden",
                                }}
                              />
                            </div>
                          </TableRow>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <TableRow key={key}>
                          <TableCell
                            style={{
                              backgroundColor: "#E2E2EA",
                              width: "180px",
                              border: "1px solid #E2E2EA",
                            }}
                          >
                            {head.label}
                          </TableCell>
                          <TableCell style={{wordBreak:'break-word'}}>
                            {" "}
                            {head?.key === "name"
                              ? row?.form?.name
                              : head.key === "visitedBy"
                              ? row?.interactionBy?.shortName
                              : head?.key === "childName"
                              ? row.name
                              : head.key === "username"
                              ? row?.interactionBy?.username
                              : head?.key === "interactionType"
                              ? row?.form?.name
                              : head?.key === "customer"
                              ? row?.customer?.name
                              : head?.key === "invoiceAmount"
                              ? row.invoiceAmount
                              : head?.key === "pendingcustomer"
                              ? row.customer
                              : head?.key === "salescustomer"
                              ? row.customer
                              : row[head?.key]}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
              {index < data.length - 1 && (
                <div
                  style={{ borderTop: "1px solid #ccc", margin: "20px 0" }}
                />
              )}
            </React.Fragment>
          ))}
        </TableContainer>
      ) : (
        <TableContainer className="tableResponsive" component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers?.map((header) => (
                  <TableCell key={header?.key} {...header?.headerProps}>
                    {header?.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data !== null &&
                data?.length > 0 &&
                data?.map((d, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {headers?.map((head) => {
                        const El = head?.element;
                        const key = `${i}-${head?.key}`;
                        if (El) return <El row={d} />;
                        else {
                          return (
                            <TableCell key={key} align="left" {...head?.props}>
                              {head?.key === "name"
                                ? d?.form?.name
                                : head.key === "visitedBy"
                                ? d?.interactionBy?.shortName
                                : head?.key === "childName"
                                ? d.name
                                : head.key === "username"
                                ? d?.interactionBy?.username
                                : head?.key === "interactionType"
                                ? d?.form?.name
                                : head?.key === "customer"
                                ? d?.customer?.name
                                : head?.key === "invoiceAmount"
                                ? d.invoiceAmount
                                : head?.key === "pendingcustomer"
                                ? d.customer
                                : head?.key === "salescustomer"
                                ? d.customer
                                : d[head?.key]}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default TableBox;
