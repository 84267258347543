import React, { useReducer, useContext, createContext } from 'react'
import _concat from 'lodash/concat'
import { AuthContext } from './AuthContext';

export const FormActions = {
  SET_FORMS: 'SET_FORMS',
  RESET_FORM: 'RESET_FORM',
  SET_CURRENT_CITY: 'SET_CURRENT_CITY',
  SET_CURRENT_CITY_DROPDOWN_DATA: 'SET_CURRENT_CITY_DROPDOWN_DATA',
  SET_ALL_CITIES: 'SET_ALL_CITIES',
  SET_ITEMS: 'SET_ITEMS',
  SET_CUSTOMRS: 'SET_CUSTOMERS',
  SET_ORDER_ITEMS: 'SET_ORDER_ITEMS',
  SET_FINANCE_DEPARTMENTS: 'SET_FINANCE_DEPARTMENTS',
  SET_FINANCE_LOCATIONS: 'SET_FINANCE_LOCATIONS',
  SET_FINANCE_ACCOUNTS: 'SET_FINANCE_ACCOUNTS',
  SET_SALES_INTERACTION: 'SET_SALES_INTERACTION',
  SET_SALES_TRANSACTION: 'SET_SALES_TRANSACTION',
  SET_TRANSACTION_ORDER:'SET_TRANSACTION_ORDER'
}



//initial state
export const initialState = {
  forms: {},
  isFormSet: false,
  isCitySet: false,
  isSalesInteractionSet: false,
  isAllCitySet: false,
  currentCity: {},
  cityDropdown: { cities: [], areas: [], nhoods: [], employees: [], hospitals: [] },
  customerList: [],
  allCities: [],
  items: [],
  orderItems: [],
  financeLocations: [],
  financeDepartments: [],
  financeAccounts: [],
  salesInteraction: [],
  salesTransaction: [],
  transactionOrder:[]
}

const reducer = (state, action) => {
  
  switch (action.type) {
    case FormActions.SET_FORMS:
      return {
        ...state,
        forms: action.payload,
        isFormSet: true,
      }

    case FormActions.SET_SALES_INTERACTION:
      return {
        ...state,
        salesInteraction: action.payload,
        isSalesInteractionSet: true,
      }


    case FormActions.RESET_FORM:
      return {
        ...state,
        forms: {},
        isFormSet: false,
        isCitySet: false,
      }

    case FormActions.SET_CUSTOMRS:
      return {
        ...state,
        customerList: action.payload,
      }

    case FormActions.SET_CURRENT_CITY:
      return {
        ...state,
        currentCity: action.payload,
        isCitySet: true,
      }

    case FormActions.SET_ALL_CITIES:

      return {
        ...state,
        allCities: action.payload,
        isAllCitySet: true,
        isFormSet: true
      }

    case FormActions.SET_ITEMS:

      return {
        ...state,
        items: action.payload
      }
    
      case FormActions.SET_SALES_TRANSACTION:

      return {
        ...state,
        salesTransaction: action.payload
      }
    
      case FormActions.SET_TRANSACTION_ORDER:

      return {
        ...state,
        transactionOrder: action.payload
      }

    case FormActions.SET_ORDER_ITEMS:

      return {
        ...state,
        orderItems: action.payload
      }


    case FormActions.SET_FINANCE_LOCATIONS:
      return {
        ...state,
        financeLocations: action.payload
      }


    case FormActions.SET_FINANCE_DEPARTMENTS:
      return {
        ...state,
        financeDepartments: action.payload
      }

    case FormActions.SET_FINANCE_ACCOUNTS:
      return {
        ...state,
        financeAccounts: action.payload
      }

    case FormActions.SET_CURRENT_CITY_DROPDOWN_DATA:
      let employeeArr = []

      let employees = action.payload[0]?.Employee?.filter(emp => emp.isActive === true)
      if (employees) {
        employees.forEach((emp) => {
          employeeArr.push({
            group: emp.employeeType[0].name,
            name: emp.person.name,
            id: Number(emp.id),
            shortName: emp.shortName,

          })
        })
      };
      let hospitalArr = []

      let hospitals = action.payload[0]?.Hospital.filter(hos => hos.isActive === true)
      if (hospitals) {
        hospitals.forEach((hos) => {
          hospitalArr.push({
            name: hos.name,
            id: hos.id
          })
        })
      };


      let areas = []
      let nhoods = []

      let cities = action.payload.filter((c) => c.isActive === true)

      cities.forEach((city) => {
        areas = _concat(areas, city.area)
      })

      areas = areas.filter((a) => a.isActive === true)

      areas.forEach((area) => {
        nhoods = _concat(nhoods, area.nhood)
      })

      nhoods = nhoods.filter((n) => n.isActive === true)
      // label-value format to make it easier to work with autocomplete
      return {
        ...state,
        cityDropdown: {
          cities: cities.map((city) => ({
            id: city.id,
            value: city.name,
            label: city.name,
            // shortName: city.shortName
          })),
          areas: areas.map((area) => ({
            id: area.id,
            value: area.name,
            label: area.name,
            city: area.city.name,
          })),
          nhoods: nhoods.map((nhood) => ({
            id: nhood.id,
            value: nhood.name,
            label: nhood.name,
            area: nhood.area.name,
          })),
          employees: employeeArr,
          hospitals: hospitalArr,
        },
      }

    default:
  }
}

export const FormContext = createContext({ formState: initialState, formDispatch: ({ type: string, payload: any }) => { } })

export const FormContextProvider = (props) => {
  const [formState, formDispatch] = useReducer(reducer, initialState);

  return (
    <FormContext.Provider value={{ formState, formDispatch }}>
      {props.children}
    </FormContext.Provider>
  )
}

export const useFormReducer = () => useContext(FormContext);

export default FormContextProvider
