import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography} from "@mui/material";
import TabBox from "components/common/TabBox";
import Person from "./person";
import Children from "./children";
import Interaction from "./interaction";
import AutocompleteBox from "components/common/AutocompleteBox";
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import "../../utils/rules";
import "./crm.scss";
import SearchInput from "components/common/extra/SearchInput";
import { useLazyQuery , useMutation} from "@apollo/client";
import { PERSON_QUERY, GET_ITEMS_QUERY, CITY_LIST_CUSTOMER  } from "graphql/query";
import { Employee_SET_QUERY, Mother_SET_Query, PERSON_SET_QUERY } from "graphql/person";
import personState from "store/atoms/person/personAtom";
import { useRecoilState } from "recoil";
import personIdAtom from "store/atoms/person/personIdAtom";
import motherIdAtom from "store/atoms/person/motherIdAtom";
// import motherIdAtom from "store/atoms/person/motherIdAtom";
import userDeleteAtom from "store/atoms/person/userDeleteAtom";
import childAtom from "store/atoms/person/childDeleteAtom";
import { toast } from "react-toastify";
import { PERSON_CHILD_CREATE_MUTATION } from "graphql/mutation";
import motherPersonIdatom from "store/atoms/person/motherPersonId";
import { IdContext } from "context/IdContext";
import { AuthContext } from "context/AuthContext"
import storeState from "store/atoms/person/store";
import { CitySearch } from "components/common/CitySearch";
import MotherState from "store/atoms/person/motherAtom";

const searchData = [
  { label: 'The Shawshank Redemption' },
  { label: 'The Godfather' },
]

const searchCityData = [
  { label: 'BOR' },
  { label: 'LSC' },
  { label: 'KIS' },
  { label: 'JIG' },
  { label: 'DGX' },
  { label: 'HGA' },
]

const schema = yup.object({
  name: yup.string().required(),
  phoneNumber: yup.number()
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .required('This field is required.'),
  username: yup.string().required().email(),
  gender: yup.string().required(),
  password: yup.string().required(),
  city: yup.string().required(),
  area: yup.string().required(),
  nhood: yup.string().required(),
})


interface IFormInputs {
  name: string,
  email: string,
}


const Crm = () => {
  const [personLoader, setPersonLoader] = useState(false)
  const [childrenLoader, setChildrenLoader] = useState(false)
  const [interactionLoader, setInteractionLoader] = useState(false)
  const [searchUserList, setSearchUserList] = useState([]);
  const [loader, setLoader] = useState(false)
  const { handleSubmit, control, reset, formState: { errors } } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });
  const [selectValue, setSelectedValue] = useState()
  const [searhValue, setSearchValue] = useState('')
  const [open, setOpen] = useState(false)
  const [person, setPerson] = useRecoilState(personState);
  const [personID, setPersonId] = useRecoilState(personIdAtom)
  const [motherID, setMotherId] = useRecoilState(motherIdAtom)
  const [store, setStore] = useRecoilState(storeState)
  const [userDeleteData, setUserDeleteData] = useRecoilState(userDeleteAtom)
  const [childDeleteData, setChildDeleteData] = useRecoilState(childAtom)
  const [localpersonId, setLocalPersonId] = useState(null)
  const [motherPersonId, setMotherPersonId] = useRecoilState(motherPersonIdatom)
  const [mother, setMother] = useRecoilState(MotherState)
 
  const navigate = useNavigate();

  const context = IdContext
  const PersonIdContext = useContext(context)
  const { userDetails } = useContext(AuthContext);

  const onInputChange = (e, v) => {
    let value = e?.target?.value
    setSearchValue(v)

    if (e !== null && value && value !== 0) {
      setLoader(true)
      setTimeout(() => {
        getUsers({ variables: { search: e.target.value } })
      }, 2000);
    }

    else if (v === "" || e === null) {
      setSearchValue("")  
    }

  }



  const handleSelect = (e, value) => {

    let param = parseInt(value.id)
    setLocalPersonId(param)
    setPersonId(param)
    if (param) {
      getUserDetails({ variables: { id: param } })
    }
  }

  //Get User Function
  const [getUsers, { loading, error, data }] = useLazyQuery(PERSON_QUERY, {
    onCompleted(data) {
      setPersonLoader(true)
      if (data && data.persons) {
        let result = data?.persons?.map((store) => ({
          value: `${store?.phoneNumber} | ${store?.name} | ${store?.city.shortName}`,
          label: `${store?.phoneNumber} | ${store.name} | ${store.city.shortName}`,
          id: store.id,
        }))

        if (result?.length === 0) {
          setOpen(false)
        }
        setSearchUserList([...result])
        setLoader(false)

        setTimeout(() => {
          setPersonLoader(false)
        }, [1000])

      }
    },
    onError(error) {
      console.error(error);
      setLoader(false)
      setPersonLoader(false)

    },
  })


  //Get User Details 
  const [getUserDetails] = useLazyQuery(PERSON_SET_QUERY, {

    onCompleted(data) {
   
      if (data && data.person) {
        const person = data?.person
        const personId = parseInt(person?.id)
        setMotherPersonId(personId)
        setUserDeleteData(false)
        setPerson(person)
        getMotherDetails({ variables: { id: personId } })

        getPreferredStore({ variables: { id: parseInt(person?.city?.id) } })

      }
    },
    onError(error) {
      console.error(error);
      setLoader(false)
    },
  })

  const [getPreferredStore] = useLazyQuery(CITY_LIST_CUSTOMER, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setStore(data)
    },
    onError(error) {
      console.log(error)
    },
  })

  const [getMotherDetails] = useLazyQuery(Mother_SET_Query, {
    onCompleted(data) {
      setMother(data?.mother)
      const motherId = parseInt(data?.mother?.id)
      let result = { ...person, ...data }
      setPerson(result)
      if (data?.mother)
        setMotherId(motherId)
      const personId = parseInt(person?.id)
    },
    onError(error) {
      console.log(error)

    },
  })
  const [getEmployeeDetails, { }] = useLazyQuery(Employee_SET_QUERY, {
    onCompleted(data) {
    },
    onError(error) {
      console.log(error)
    },
  })





  return (
    <Grid className="crmPage">
      <Grid className="crmPage__title">
        <Box className="pageTitle">
          <Typography variant="h3">CRM List</Typography>
          <ul className="breadcrumb">
            <li>Dashboard</li>
            <li>CRM</li>
          </ul>
        </Box>
        <SearchInput title={'Search Phone'} addClass={'searchBox'} data={searchUserList} onInputChange={onInputChange}
          handleSelect={handleSelect}
          value={searhValue} loading={loader}
          open={open}
        />
        <CitySearch  />
            </Grid>

      <Grid className="crmPage__content">
        <TabBox
          items={[
            { element: <Person loader={personLoader} />, label: "Person" },
            { element: <Children />, label: "Children" },
            { element: <Interaction />, label: "Interaction" },
           
          ]}
        />
      </Grid>
    </Grid>
  );
};
 export default Crm;
