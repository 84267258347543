import React, { useState } from "react";
import { Avatar, Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "assets/images/logo.png";
import user from "assets/images/user.jpg";
import useCurrentPath from "routes/useCurrentPath";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "components/common/Popover";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "./header.scss";
import { AuthContext } from "context/AuthContext";
import { useApolloClient } from '@apollo/client';
import { RecoilRoot, useRecoilCallback } from 'recoil';
import personState from "store/atoms/person/personAtom";




const menuList = [
  {
    id: 1,
    text: "CRM",
    path: "/crm",
  },
  {
    id: 2,
    text: "Sales",
    path: "/sales",
  },
  {
    id: 3,
    text: "Finance",
    path: "/finance",
  },
];

const userList = [
  {
    id: 1,
    title: "Log out",
    path: "/",
  },
];



const Header = () => {
  const apolloClient = useApolloClient();
  // Define the callback using useRecoilCallback
  const onLogoutCallback = useRecoilCallback(({ reset }) => async () => {
    reset(personState);
  })
  const useAuthContext: any = React.useContext(AuthContext);

  const { allowApps } = useAuthContext

  const navigate = useNavigate();
  const currentPath = useCurrentPath();
  const [showMenu, setShowMenu] = useState(false);
  const changeMenu = (item: string) => {
    navigate(item);
    setShowMenu(false)
  };
  const onMenu = () => {
    setShowMenu(!showMenu)
  }




  const onLogout = () => {
    // Call the useRecoilCallback function
    onLogoutCallback();

    // Clear Apollo Client cache
    useAuthContext.setToken('')
    useAuthContext.mappUserDetails()
    useAuthContext.setAllowedApps([])
    apolloClient.clearStore().then(() => {
      localStorage.removeItem("t")


    })
    navigate("/")


  }

  const filteredMenuList = menuList.filter(menuItem => {
    return allowApps.some(allowedApp => menuItem.text.toLowerCase().includes(allowedApp.toLowerCase()));
  });

  return (
    <Grid component="header" className="header">
      <Button variant="text" className={`header__menuBtn ${showMenu ? 'active' : ''}`} onClick={onMenu}>
        {showMenu ? <CloseIcon /> : <MenuIcon />}
      </Button>
      <Box className={`header__menu ${showMenu ? 'show' : ''}`}>
        <ul>
          {filteredMenuList.map((item) => (
            <li
              key={item.id}
              className={currentPath === item.path || currentPath.includes(item.path) ? "active" : ""}
              onClick={() => changeMenu(item.path)}
            >
              {item.text}
            </li>
          ))}
        </ul>
      </Box>
      <Box className="header__user">
        <Box className="dropdown">
          <Popover
            items={userList.map((item) => ({
              ...item,
              onClick: () => onLogout(),
            }))}
          // openEl={ <ArrowDropUpIcon />}
          // closeEl={<ArrowDropDownIcon />}
          >
            <Button variant="text" className="dropdown__toggle">
              <Avatar
                sx={{ bgcolor: "#ffffff" }}
                alt="Logo"
                src={Logo}
              />
              <span className="name">{useAuthContext?.userDetails?.username}</span>
              {false ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
          </Popover>
        </Box>
      </Box>
    </Grid>
  );
};
export default Header;
