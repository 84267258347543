import { Box, CircularProgress } from "@mui/material"



const CustomLoader=()=>{
return (
    <Box sx={{ width: '100%',textAlign:'center' ,marginTop:'20px',marginBottom:'20px' }}>
    <CircularProgress />
  </Box>
)
}

export default CustomLoader