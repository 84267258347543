import React, { useMemo, useState } from 'react';
import { AutoCompleteData } from 'modals/select';
import { Box, TextField, Autocomplete } from '@mui/material';
import { Controller, useFormState, useForm } from "react-hook-form";

type SearchInputProps = {
    title?: string;
    addClass?: string;
    required?: boolean;
    data: AutoCompleteData[];
    control?: any;
    name?: string;
    type?: string;
    onInputChange: (e, v) => void;
    handleSelect: (e ,v) => void,
    value?: string,
    loading?: boolean
 

}

const SearchInput: React.FC<SearchInputProps> = ({ title, data, required, addClass, type, control, name, onInputChange, handleSelect, value, loading }) => {

    const [open, setOpen] = useState(false)


    return (
        <Box className={`formGroup ${addClass}`}>
            <Autocomplete
                open={open}
                onOpen={() => setOpen(true)}
                onClose={()=>setOpen(false)}
                disablePortal
                size='small'
                id="autoComplete"
                options={data}
                filterSelectedOptions={true}
                clearOnEscape={true}
                loading={loading}
                sx={{ width: '100%' }}
                inputValue={value}
                clearOnBlur={false}
                onChange={handleSelect}
                onInputChange={(e, v) => onInputChange(e, v)}
                renderInput={(params) => <TextField {...params} size="small" required={required} label={title} />}
            />


        </Box>
    )
}
export default SearchInput;