import React, { useContext, useEffect, useState } from "react";
import InputBox from "components/common/InputBox";
import RadioBox from "components/common/RadioBox";

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import "../common/common.scss";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import "../../utils/rules";
import { useLazyQuery, useMutation } from "@apollo/client";
import { PERSON_BASE_CREATE_MUTATION } from '../../graphql/mutation';
import { toast } from "react-toastify";
import { KEYS, listProviderAtom } from "store/atoms/city";
import { useRecoilState } from "recoil";
import personIdAtom from "store/atoms/person/personIdAtom";
import { FormContext } from "context/FormContext";
import { Employee_SET_QUERY, Mother_SET_Query, PERSON_SET_QUERY } from "graphql/person";
import personState from "store/atoms/person/personAtom";
import AutocompleteBoxArea from "components/common/Autocompleteboxarea";
import MotherState from "store/atoms/person/motherAtom";
import motherPersonIdatom from "store/atoms/person/motherPersonId";
import motherIdAtom from "store/atoms/person/motherIdAtom";
import { CITY_LIST_CUSTOMER } from "graphql/query";
import storeState from "store/atoms/person/store";

const genderList = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
];


const schema = yup.object({
  name: yup.string().required(),
  phone: yup.number()
    .typeError('This field is required.') // Custom message for not being a number
    .required('This field is required.'),
  gender: yup.string().required(),
  cityPerson: yup.string().required(),
  areaPerson: yup.string().required(),
  nhoodPerson: yup.string().required(),
})


interface IFormInputs {
  name: string,
  email: string,
  phone: any,
  gender: any,
  city: any,
  nhood: any,
  area: any,
}

type PersonBaseDialogProps = {
  title?: string;
  open: boolean;
  handleClose: () => void;
  persondata: any,
  type: boolean
};

const PersonBaseDialog: React.FC<PersonBaseDialogProps> = ({
  title,
  open,
  handleClose,
  persondata,
  type,
}) => {
  const [modifiedNhoods, setModifiedNhoods] = useState([])
  const [submitLoader, setSubmitLoader] = useState(false)
  const { formState } = useContext(FormContext);
  const { cities, areas, nhoods } = formState.cityDropdown
  const [person, setPerson] = useRecoilState(personState);
  const [mother, setMother] = useRecoilState(MotherState)




  let cityId = persondata?.nhood?.id;
  const [motherPersonId, setMotherPersonId] = useRecoilState(motherPersonIdatom)

  const defaultValues = {
    name: type ? persondata?.name : '',
    phone: type ? persondata?.phoneNumber : null,
    gender: type ? persondata?.gender : '',
    cityPerson: type ? persondata?.city?.id : '',
    nhoodPerson: type ? persondata?.nhood?.id : '',
    areaPerson: type ? persondata?.area?.id : '',
  }


  const { handleSubmit, control, getValues, setValue, reset, watch, formState: { errors } } = useForm<IFormInputs>({
    defaultValues,
    resolver: yupResolver(schema)
  });

  const [_, setPersonId] = useRecoilState(personIdAtom)

  const [personBaseData, setPersonBaseData] = useRecoilState(listProviderAtom(KEYS.PERSON_BASE_TABLE))

  const onSubmit = (data: IFormInputs) => {

    let inputData = {
      ...data,
      nhood: parseInt(data.nhoodPerson),
      city: parseInt(data.cityPerson),
      area: parseInt(data.areaPerson),
      phone: String(data.phone)
    }
    if (type)
      inputData.id = parseInt(persondata?.id)
    setSubmitLoader(true)
    createPersonBase({ variables: { ...inputData } });
  }

  //Create person base Function
  const [createPersonBase, { data, loading, error }] = useMutation(PERSON_BASE_CREATE_MUTATION, {
    onCompleted(data) {

      setSubmitLoader(false)

      let paramId = parseInt(data?.CreatePerson?.id)
      setMotherPersonId(Number(paramId))

      getUserDetails({ variables: { id: paramId } })

      toast.success(type ? "User is updated successfully" : "User is created successfully")
    },
    onError(error) {
      toast.error(error.message)
      setSubmitLoader(false)

    }
  });


  const [getUserDetails] = useLazyQuery(PERSON_SET_QUERY, {
    onCompleted(data) {

      if (data && data.person) {
        const person = data?.person
        const personId = parseInt(person?.id)

        setPerson(data?.person)
        setMother({})
        setPersonId(personId)
       
        handleClose()
      }
    },
    onError(error) {
      console.error(error);
      // setLoader(false)
    },
  })




  const watchArea = watch("areaPerson",)



  useEffect(() => {

    const value = getValues("areaPerson");

    let area = areas?.find(me => me.id === value)?.value
    let hoods = nhoods?.filter(me => me.area === area)
    setModifiedNhoods([...hoods])
  }, [watchArea, watch])



  const onError = (errors, e) => {
    console.log(errors, e);
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        className: "personBaseDialog",
      }}
    >
      {open && <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid className="row">
            <InputBox type="text" value={persondata?.name} title="Name" control={control} name="name" error={
              errors?.name?.message} />

            <InputBox type="number" title="Phone Number" control={control} name="phone" error={errors?.phone?.message} />

            <RadioBox title="Gender:" vertical data={genderList} control={control} name="gender" error={errors?.gender?.message} />


            <AutocompleteBoxArea title={"City"} data={cities} control={control} name="cityPerson" error={
              errors?.cityPerson?.message} />


            <AutocompleteBoxArea title={"Area"} data={areas} control={control} name="areaPerson" error={
              errors?.areaPerson?.message} />

            <AutocompleteBoxArea
              title={"Neighborhood"}
              data={modifiedNhoods}
              control={control}
              name="nhoodPerson"
              error={
                errors?.nhoodPerson?.message}
            />

          </Grid>
        </DialogContent>
        {submitLoader ?
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <CircularProgress />
          </Box> :
          <DialogActions>
            <Button className="btn-text" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="btn-text" type="submit">
              {type === true ? 'Update' : 'Save'}
            </Button>
          </DialogActions>}
      </form >}
    </Dialog>
  );
};

const T = (props) => {
  if (!props.open) return null;
  return <PersonBaseDialog {...props} />
}
export default T;
