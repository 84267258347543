import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,

} from "@mui/material";

import { FormContext } from "context/FormContext";





type ViewTransactionDialogProps = {
    title?: string;
    open: boolean;
    handleClose: () => void;
    rowData: any
};



const ViewTransactionDialog: React.FC<ViewTransactionDialogProps> = ({
    title,
    open,
    handleClose,
    rowData

}) => {

    // const getQty=(data)=>{
    //  let result=data?.map(item=>Number(item.qty)).reduce((total, current)=>total+current,0); 
    // return result;

    // }

 

    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{
                className: "ViewTransactionDialog",
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>

                <List >
                    <ListItem >
                        Date: {rowData?.date}

                    </ListItem>
                    <ListItem >Document:  {rowData?.document}</ListItem>
                    <ListItem >Amount:  {rowData?.amount}</ListItem>
                    <ListItem > rep:  {rowData?.rep}</ListItem>
                    <ListItem > Memo:  {rowData?.memo}</ListItem>
                </List>

                <Grid sx={{ marginBottom: "20px" }}>
                    <TableContainer component={Paper} className="tableResponsive">
                        <Table className="recordSales">
                            <TableHead className="top">
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Qty</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Curency</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowData?.details?.map((item)=> (
                                <TableRow className="mobileView">
                                    <TableCell  className="item">
                                    {item?.name}
                                    </TableCell>
                                    <TableCell className="qty">
                                    {Number(item.qty)}
                                    </TableCell>
                                    <TableCell className="price">
                                        {item?.amount}


                                    </TableCell>
                                    <TableCell className="total">
                                        {item?.currency}
                                    </TableCell>
                                </TableRow>
                                ))}
                          
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Grid>


            </DialogContent>
            <DialogActions>
                <Button className="btn-text" onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>


        </Dialog>

    );
};
export default ViewTransactionDialog;
