import React from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@mui/material";
import "./common.scss";

type DeleteDialogBoxProps = {
  title?: string;
  text?: string;
  open: boolean;
  handleClose: () => void;
  handleDelete: (e, v) => void;
};

const DeleteDialogBox: React.FC<DeleteDialogBoxProps> = ({
  title,
  text,
  open,
  handleClose,
  handleDelete,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        className: "deleteBox",
      }}
    >
      <DialogContent>
        <span className="icon">
          <DeleteOutlineIcon />
        </span>
        {title && <Typography variant="h5">{title}</Typography>}
        {text && <DialogContentText>{text}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button className="btn-text" autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button className="btn-text" onClick={handleDelete} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteDialogBox;
