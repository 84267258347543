import { atom } from "recoil";


const customerDataState = atom({
    key: 'currentcustomerDataState',
    default: {},
});
  
export default customerDataState

  
