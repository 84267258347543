import React, { useMemo, useState } from "react";
import { Box, Grid, TableCell, IconButton } from "@mui/material";
import NotFound from "components/common/NotFound";
import Popover from "components/common/Popover";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import TableBox from "components/common/TableBox";
import DeleteDialogBox from "components/common/DeleteDialogBox";
import ChildrenDialog from "components/crm/ChildrenDialog";
import { useRecoilState } from "recoil";
import personState from "store/atoms/person/personAtom";
import { CHILD_DELETE_MUTATION } from "graphql/mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import childAtom from "store/atoms/person/childDeleteAtom";
import { Mother_SET_Query, PERSON_SET_QUERY } from "graphql/person";
import MotherState from "store/atoms/person/motherAtom";

const Children = () => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openChildren, setOpenChildren] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [person, setPerson] = useRecoilState(personState);
  const [childDeleteData, setChildDeleteData] = useRecoilState(childAtom);
  const [viewChild, setViewChild] = useState()
  const [mother, setMother] = useRecoilState(MotherState)
  const [editChild, setEditChild] = useState(false)

  const popupList = useMemo(() => ([
    {
      id: 1,
      title: "Add Child",
      onClick: () => {
        setOpenChildren(true)
        setEditChild(false)
      },
    },
  ]), []);

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const headers = [
    {
      key: "childName",
      label: "Name",
    },
    {
      key: "id",
      label: "ID",
    },
    {
      key: "DOB",
      label: "Date of Birth",
    },
    {
      key: "vaccine",
      label: "Vaccine",
    },
    {
      key: "action",
      label: "Action",
      headerProps: { align: "right" },
      props: { align: "right" },
      element: ({ row }) => (
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton sx={{ color: "#3f51b5" }}
              onClick={() => {
                setOpenChildren(true)
                setEditChild(true)
                setViewChild(row)
              }}
            >
              <VisibilityIcon style={{ fontSize: 18 }} />
            </IconButton>
            <IconButton sx={{ color: "#f50057" }} onClick={() => {
              setItemToDelete(row)
              setOpenDelete(true)
            }}>
              <DeleteIcon style={{ fontSize: 18 }} />
            </IconButton>
          </Box>
        </TableCell>
      ),
    },
  ];

  const handleDelete = () => {
    deleteChildBasePerson({ variables: { id: parseInt(itemToDelete?.id) } })
  }

  const [deleteChildBasePerson, { data, loading, error }] = useMutation(CHILD_DELETE_MUTATION, {
    refetchQueries: [{
      query: Mother_SET_Query, variables: {
        id: parseInt(person?.id),
      }
    }],
    onCompleted(data) {
      toast.success("Child is deleted successfully")
      setOpenDelete(false)
      setChildDeleteData(false)
    },
    onError(e) {
      toast.error(e.message)
      setOpenDelete(false)
    },
  })
  return (
    <React.Fragment>
      {mother?.child?.length ? (
        <Grid className="person">
          <TableBox headers={headers} data={mother?.child} />
        </Grid>
      ) : (
        <NotFound text="No data found of children" />
      )}

      <DeleteDialogBox
        open={openDelete}
        handleClose={handleDeleteClose}
        title="Delete"
        handleDelete={handleDelete}
        text={`Are you sure you want to delete ${itemToDelete?.id}?`}
      />


   { openChildren &&  <ChildrenDialog open={openChildren} handleClose={() => setOpenChildren(false)}
        persondata={viewChild} type={editChild}
        title="Children" />}

      <Popover
        items={popupList}
      >
        <IconButton aria-label="add" className="addPopup">
          <AddIcon />
        </IconButton>
      </Popover>
    </React.Fragment>
  );
};
export default Children;
