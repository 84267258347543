import React, { useMemo, useState } from "react";
import { Box, Grid, TableCell, IconButton, CircularProgress } from "@mui/material";
import NotFound from "components/common/NotFound";
import Popover from "components/common/Popover";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableBox from "components/common/TableBox";
import MakePaymentDialog from "components/sales/MakePaymentDialog";
import { useFormReducer } from "context/FormContext";
import { useRecoilState } from "recoil";
import customerDataState from "../saleAtom";
import ViewTransactionDialog from "components/sales/ViewTransactionDialog";

const   Transactions = (props) => {
  const [openChildren, setOpenChildren] = useState(false);
  const [openView, setOpenView] = useState(false);
  const { formState, formDispatch } = useFormReducer()
 

  const [rowData, setRowData] = useState()
  const popupList = useMemo(() => ([
    {
      id: 1,
      title: "Make Payment",
      onClick: () => {
        setOpenChildren(true)
      },
    },
  ]), []);

  const handleView = (row) => {
    setRowData(row)
    setOpenView(true)
  }

  const headers = [
    {
      key: "type",
      label: "Transacton Type",
    },
    {
      key: "date",
      label: "Date",
    },
    {
      key: "amount",
      label: "Amount",
    },
    {
      key: "document",
      label: "Document",
    },
      {
      key: "action",
      label: "Action",
      headerProps: { align: "right" },
      props: { align: "right" },
      element: ({ row }) => (
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton sx={{ color: "#3f51b5" }} onClick={() => handleView(row)}>
              <VisibilityIcon style={{ fontSize: 18 }} />
            </IconButton>
          </Box>
        </TableCell>
      ),
    },
  ];



  return (
    <React.Fragment>
      {props.loader?  <Grid className="centerLoader"> <CircularProgress  color="primary"/> </Grid> : formState?.salesTransaction?.length>0 ? (
        <Grid className="person">
          <TableBox headers={headers} data={formState.salesTransaction} />
        </Grid>
      ) : (
        <NotFound text="No data found of transactions" />
      )}

    { openChildren && <MakePaymentDialog
        open={openChildren}
        customerData={props?.custdata}
        handleClose={() => setOpenChildren(false)}
        title="Make Payment"
        text={`To recored a pre-payment for ${props?.custdata?.name} in ${props?.custdata?.currency?.code}`}
      />}

{ openView && <ViewTransactionDialog
        open={openView}
        handleClose={() => setOpenView(false)}
        title="TransactionDetails"
        rowData={rowData}
      />}


      <Popover
        items={popupList}
      >
        <IconButton aria-label="add" className="addPopup">
          <AddIcon />
        </IconButton>
      </Popover>
    </React.Fragment>
  );
};
export default Transactions;
