import React from 'react';
import Img from 'assets/images/not-found-img.svg';
import { Box, Typography } from '@mui/material';
import './common.scss';


type NotFoundProps = {
    text?: string;
}

const NotFound:React.FC<NotFoundProps> = ({ text }) => {
    return (
        <Box className='notFound'>
            <img src={Img} alt={text} />
            <Typography component='small'>{text}</Typography>
        </Box>
    )
}
export default NotFound;