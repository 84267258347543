import { useLazyQuery } from '@apollo/client';
import { ME_QUERY } from 'graphql/query';
import * as React from 'react';

const initialValues = {
    setPersonContext: () => { },
    personIdContext:null,
}

export const IdContext = React.createContext(initialValues);
export const IdContextProvider = ({ children }) => {
    const [_personId, setPersonID] = React.useState<any>()
    
    const _setPersonID = () => {
        setPersonID(true)
    }


   
  

    const value = {
        setPersonContext: _setPersonID,
        personIdContext: _personId,
    
    }
    return <IdContext.Provider value={value}>{children}</IdContext.Provider>;
}

export default IdContextProvider